<template>
  <div id="app">
    <div class="appcontent">
      <Tabs></Tabs>
      <router-view />
      <Footer></Footer>
    </div>
  </div>
</template>

<script type="text/javascript">
import Tabs from "@/common/Tabs.vue";
import Footer from "@/common/Footer";
export default {
  components: {
    Tabs,
    Footer,
  },
  data() {
    return {
      _beforeUnload_time: "",
      _gap_time: "",
    };
  },
  // mounted() {
  //缩放方法
  // var browerWidth = window.innerWidth; //浏览器可视宽度
  // var baseWidth = 1920; //设计稿宽度
  // var zoomValue = browerWidth / baseWidth; //缩放比例计算
  // document.getElementById("appcontent").style.transform =
  //   "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
  // window.onresize = function () {
  //   //窗口尺寸变化时，重新计算和缩放
  //   browerWidth = window.innerWidth;
  //   zoomValue = browerWidth / baseWidth;
  //   document.getElementById("appcontent").style.transform =
  //     "scale(" + zoomValue + "," + zoomValue + ")";
  // };
  // },
  mounted() {
    window.addEventListener("beforeunload", (e) => {
      this._beforeUnload_time = new Date().getTime();
    });
    window.addEventListener("unload", async () => {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      if (this._gap_time <= 5) {
        //关闭
        //清除 cookie 或者 localStorage 的方法
        this.clearCookie();
        sessionStorage.clear();
        window.localStorage.clear();
      } else {
        // 刷新
      }
    });
  },
  destroyed() {
    window.removeEventListener("beforeunload");
    window.removeEventListener("unload");
  },
  methods: {
    //清空cookie
    clearCookie() {
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
        for (var i = keys.length; i--; ) {
          document.cookie =
            keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); //清除当前域名下的,例如：m.kevis.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=" +
            document.domain +
            ";expires=" +
            new Date(0).toUTCString(); //清除当前域名下的，例如 .m.kevis.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=kevis.com;expires=" +
            new Date(0).toUTCString(); //清除一级域名下的或指定的，例如 .kevis.com
        }
      }
      console.log("已清除cookie");
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: "SourceHanSansCN-Normal";
}

#app {
  // width: 100vw;
  min-height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1300px;
  .appcontent {
    overflow: hidden;
    width: 100%;
  }
}
</style>
