import http from "@/utils/https.js";

export function getXinxi() {
  return http.get("/trade/advice/getZCAdvice");
}
//获取钢材数据
export function getGangcai() {
  return http.get("/trade/crawling/getSteelList");
}
//获取煤炭数据
export function getMeitan() {
  return http.get("/trade/crawling/getCoalList");
}
//获取水泥数据
export function getShuini() {
  return http.get("/trade/crawling/getCementList");
}
//获取终点站信息
export function getZhongDian(id) {
  return http.get(`/system/scmPark/selectEndStationList?id=${id}`);
}

//查询运费
export function getYunFei(start, end) {
  return http.get(
    `/system/scmPark/selectParkRailWayFeigt?scmRailwayStartStationId=${start}&scmRailwayEndStationId=${end}`
  );
}

//查询运费
export function getKuaiBao() {
  return http.get("/trade/advice/getNewAdvice");
}

//查询运费表
export function getYunFeiBiao() {
  return http.get("/system/scmPark/selectStationPrice");
}
//参与贸易背景列表
export function getJoinList() {
  return http.get("/trade/joinList");
}
// 接收邀请
export function invited(data) {
  return http.post("/trade/invite", data);
}
//退出登陆
export function logout() {
  return http.delete("/scm-auth/logout");
}
//获取阳光采购
export function getSupplyShow() {
  return http.get("/system/config/configKey/sys.supply.show");
}
//获取优质贸易
export function getRecommend() {
  return http.get("/trade/recommend/list");
}
//获取首页swiper
export function getNewBanner(a,b) {
  return http.get("/system/business/getNewBanner?moduleValue=" + a +"&location=" + b);
}
