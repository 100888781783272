import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.css';
import './assets/font/index.css'
import './assets/style/public.scss' // 公共 css
import VueAwesomeSwiper from "vue-awesome-swiper/dist/vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;




Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);
Vue.prototype.imageUrl = function(img){
	return `https://oss.zechengscm.com/api/data/images/dazong/image/${img}.png`;
}


//防多次点击，重复提交
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
    })
  }
});


new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
