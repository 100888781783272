import http from "@/utils/https.js";


// 手机号登录
export function phoneLogin(data) {
    return http.post('/scm-auth/loginScm', data)
}
// 发送验证码
export function sendCode(params) {
    return http.get('system/code/getVerCode', params)
}
// 验证验证码
export function verifyCode(params) {
    return http.post('system/code/checkCode', params)
}
// 获取验证码
export function getCodeImg() {
    // return request({
    //     url: '/code',
    //     headers: {
    //         isToken: false
    //     },
    //     method: 'get',
    //     timeout: 20000
    // })
    return http.get('/code')
}
