// 引入axios
import axios from 'axios'
import {Notification, MessageBox, Message, Loading} from 'element-ui'
import errorCode from '@/utils/errorCode'
const service = axios.create({
    baseURL: "/api", timeout: 5000,
    // baseURL: "/prod-api", timeout: 5000,
    // `withCredentials` 表示跨域请求时是否需要使用凭证
    withCredentials: false,
    // default,
    // headers是即将被发送的自定义请求头
    headers: {
        'Content-type': 'application/json'
    }
})
// 是否显示重新登录
export let isRelogin = {
    show: false
};
// 添加请求拦截器
service.interceptors.request.use(function (config) { 
    const token = sessionStorage.getItem("token")
    if (token!=null) {
        config.headers.Authorization = token
    }
    return config;
}, function (error) { 
    return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(res => { // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
        return res.data
    }
    if (code === 401) {
        if (! isRelogin.show) {
            isRelogin.show = true;
            MessageBox.confirm('登录状态已过期，请重新登录', '系统提示', {
                confirmButtonText: '去登录',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => { 
                console.log('重新登录');
                isRelogin.show = false;
                clearCookie();
                sessionStorage.clear();
                localstorage.clear(); 
                location.href = '/home?reload=true';
                // this.$router.push({path:"/home",query:{'reload':true}});
                console.log(1111,this.$router);
            }).catch(() => {
                isRelogin.show = false;
            });
        } 
        return Promise.reject(res.data.msg)
    } else if (code === 500) {
        Message({message: msg, type: 'error'})
        return Promise.reject(new Error(msg))
    } else if (code !== 200) {
        Notification.error({title: msg})
        return Promise.reject('error')
    } else {
        return res
    }
}, error => {
    console.log('err' + error)
    let {message} = error;
    if (message == "Network Error") {
        message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
    })
    return Promise.reject(error)
})
		//清空cookie
		function clearCookie() {
			var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
			if (keys) {
				for (var i = keys.length; i--;) {
					document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的,例如：m.kevis.com
					document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的，例如 .m.kevis.com
					document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString();//清除一级域名下的或指定的，例如 .kevis.com
				}
			}
			console.log('已清除cookie');
		}

export default service
