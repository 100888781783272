<template>
  <div>
    <el-dialog title="《隐私保护政策》" :visible.sync="showyinsi" width="30%" append-to-body>
      <div class="text-content">
        <p class="title">《隐私保护政策》</p>
        <p>版本及更新</p>
        <p>当前版本：2023年4月版</p>
        <p>
          历史版本：
          <span class="text-orange"> 2022年6月版 2021年9月版 2021年1月版</span>
        </p>
        <div class="textarea">
          泽诚数字大宗隐私政策
          <br />本隐私权政策旨为陕西泽诚数字科技有限公司旗下的泽诚数字大宗平台注册用户 （以下简称“用户”或“您”），介绍您在使用本平台提供的服务时，您应当了解的内容：
          <br />1. 我们如何收集您的个人信息
          <br />2. 我们如何使用Cookie和同类技术
          <br /> 3. 我们如何存储和保护您的个人信息
          <br />4. 我们如何使用您的个人信息
          <br />5. 我们如何共享、转让、公开披露您的个人信息
          <br />6. 您的权利
          <br />7. 本政策的更新

          <br /><br />【特别提示】本政策与您使用我们的服务关系紧密，请您在使用我们的产品服务前，仔细阅读并充分理解本政策，做出您认为适当的选择。您点击或勾选泽诚数字大宗平台相关页面上的“同意”键或者以其他方式确认同意/接受本政策
          (如在相关页面上点击后续操作或已实际享有本政策项下的权益或已实际接受泽诚数字大宗的服务或已实际执行本政策下的部分约定等)即表示您接受本政策全部条款内容的约定并同意我们按照本政策处理您的个人信息。本政策以线上数据电文的形式订立，对您与泽诚数字大宗均具有约束力。

          <br /> <br />1. 我们如何收集您的个人信息

          <br />个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本政策中包括: 个人资料信息
          (包括但不限于姓名、性别、出生年月日、身份证信息、身份证照片、真实头像照片、住所地、手机号码、电子邮箱地址、验证信息、验证信息凭证等。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本政策中涉及的个人敏感信息包括:
          手机号码、用户密码、身份证或其他身份证明、面部识别特征、位置信息。匿名化处理后的信息以及其他无法识别特定自然人身份或者反映特定自然人活动情况的信息不属于个人信息。我们仅会出于以下目的，收集和使用您的个人信息:

          <br /><br />1.1
          您须授权我们收集和使用您的个人信息的情形我们的产品和服务包括一些基本业务功能和扩展业务功能。基本业务功能包含了实现个人与企业信息认证所必需的功能、及保障服务正常运行和交易安全所必需的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品或服务。这些基本业务功能必须收集的信息包括：

          <br />1.1.1 用户注册、认证所必需的信息
          <br /> 您使用泽诚数字大宗平台服务前，需要在泽诚数字大宗平台创建账号并完善个人资料。通过用户主动提供的方式，泽诚数字大宗会收集您的以下信息:
          <br /> (1)
          当您注册成为泽诚数字大宗企业用户或登录泽诚数字大宗企业账号时您需要向泽诚数字大宗提供您本人的手机号码，并准确输入泽诚数字大宗以短信形式发送至您手机的验证码，且允许泽诚数字大宗获取您的以短信形式发送至您手机的验证码，为您提供泽诚数字大宗平台服务。如果您拒绝提供手机号码和验证码，泽诚数字大宗平台将无法为您创建账号并提供服务。

          <br /> (2)
          当您在平台进行实名认证时，需要向泽诚数字大宗提供您个人的姓名、身份证号、身份证照片、面部识别特征信息或您的法定代表人/负责人/经营者/代表的姓名、身份证照片、身份证号码及/或公司的名称、名片照片、统一信用代码证照片、营业执照照片、住所地。泽诚数字大宗收集上述信息，是基于法律法规要求、保护用户人身财产安全、保障交易真实、依照平台规则处理用户纠纷之需要。为进行实名认证，泽诚数字大宗将向泽诚数字大宗合作的第三方提交您的前述信息，并从合法保存有您个人信息的第三方处获得、保存有关您身份信息的验证结果，您将无法作为泽诚数字大宗企业用户使用泽诚数字大宗平台服务。

          <br /> 1.1.2 用户服务所必需的信息:
          当您与泽诚数字大宗客服联系时，为验证您的身份，帮助您解决问题，泽诚数字大宗会以通话录音、人工录入 等方式记录您的通话信息、通话内容以及您提供的姓名、联系方式等为解决问题所需的必要
          信息，以便泽诚数字大宗处理纠纷、解决问题或向您反馈结果。如果您拒绝提供此类信息，会导致您无法使用泽诚数字大宗的客服服务。

          <br />1.1.3 维护服务正常运行、保障交易安全所必需的信息 当您使用泽诚数字大宗小程序时，为保障您正常使用我们的服务，维护我们服务的正常运行，改进及
          优化我们的服务体验以及保障您的账号安全，我们会收集您的设备型号、操作系统、唯一设备标识符、登陆IP地址、泽诚数字大宗企业软件版本号、接入网络的方式、类型和状态、网络质量数据、设备加速器
          (如重力感应设备)等设备信息以及操作日志、服务日志信息 (如您在搜索、查看的信息、服务故障信息、引荐网址等信息》 等日志信息，这类信息是为提供服务 必须收集的基础信息。

          <br /><br />1.2 您可选择是否授权我们收集和使用您的个人信息的情况除上述基本业务功能我们会收集和使用您的个人信息以外我们的产品和服务还包括一些扩
          展业务功能，我们可能会在您使用扩展业务功能时收集和使用您的个人信息，如果您不提供这些个人信息，您依然可以实现物流信息交换及在线货物运输交易，但您可能将无法使用这些扩展业务功能:

          <br />1.2.1 基于设备权限开启的扩展业务功能
          <br /> (1) 基于摄像头 (相机)的扩展功能: 您可以授权泽诚数字大宗访问您的摄像头(相机)进行视频拍摄、拍照以及扫码，我们会识别您的视频、照片以及扫码信息来完成用户认证及扫码支付功能。
          <br /> (2) 基于相册 (图片库) 的扩展功能:
          <br /> 您可以授权泽诚数字大宗访问您的相册并在泽诚数字大宗上传您的照片来实现用户认证、客户服务以及评价功能，我们会使用您所上传的照片来识别您的认证信息、理赔资料信息、客户服务信息或使用包含您所上传图片的评价。

          <br /> 1.2.2 参与运营活动
          <br />
          当您参加泽诚数字大宗的运营活动时，泽诚数字大宗需要您提供某些信息，包括但不限于您的姓名、手机号码、电子邮箱地址、联系地址、银行卡号。这些信息可以帮助泽诚数字大宗对活动进行宣传、与您取得联系、向您发放礼品、对活动结果进行公示等。如果您拒绝提供此类信息，可能导致您无法参加相应活动，或无法收到礼品，无法享受优惠，但不影响您使用泽诚数字大宗平台基本业务功能。我们将会在泽诚数字大宗相关页面提示您自行填写并提交或单独征求您的授权同意

          <br /> 1.2.3 平台协议文件的电子签约
          <br /> 就您在泽诚数字大宗平台上确认、同意的协议、规则、政策、文档等 (统称“协议文件”），泽诚数字大宗可能会将协议文件置于电子签约平台以数据电文形式在线签署并储存，并将您的个人信息
          (姓名、身份证号、手机号、银行卡号、电子邮箱地址及其他身份认证信息)
          报送给电子签约平台用于电子签名认证并生成数字证书，泽诚数字大宗合作的电子签约平台将托管并调用您的数字证书代您生成电子签名。泽诚数字大宗可能将协议文件内容发送到电子签约平台生成签约文本。协议文件使用的电子签约平台为可合法有效提供电子签约服务的平台。泽诚数字大宗将向电子签约平台调取协议文件、您的签约信息、您的身份认证信息及与证明协议文件有效性、您的电子签名有效性相关的其他信息。如您不同意前述电子签约相关事项，可能无法与泽诚数字大宗或第三方达成交易。

          <br /> 1.2.4 开展数据分析、不断改善产品和服务
          为开展数据分析和更好改善泽诚数字大宗平台服务，我们可能将业务中收集的个人信息用于统计分析和改进运营，例如通过您所在的位置、运单信息、浏览和搜索信息、充值或购买保险等消费记录、路线偏好等进行统计分析，从而改进我们的产品、服务或营销计划;
          又如为改进我们系统而进行的技术改造、网络维护、故障排除、内部政策与流程制定、生成内部报告等。

          <br /> 1.2.5 对第三方责任的声明
          <br /> 请您注意，您的交易相对方、您通过泽诚数字大宗平台跳转功能访问的第三方网站经营者、通过泽诚数字大宗接入的第三方服务
          (如有)可能有自己的隐私保护政策;当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会获取、使用您的个人信息，该等获取、使用不受泽诚数字大宗的控制及本政策的约束。泽诚数字大宗会尽商业上的合理努力去要求这些第三方对您的个人信息采取保护措施，但泽诚数字大宗无法保证这些第三方一定会按照泽诚数字大宗的要求采取保护措施，请您与这些第三方直接联系以获得关于他们的隐私保护政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。

          <br /> 1.2.6
          其他服务收集、使用、提供信息另行征得同意如您选择使用我们提供的其他服务，基于该服务我们需要收集您的信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息;如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。

          <br /> 1.2.7 其他用途
          <br />我们将您的个人信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的您的个人信息用于其他目的时，会单独征求您的授权同意请注意:无法与任何特定个人直接建立联系的数据，不属于个人信息。如果我们将无法与任何特定个人建立联系的数据与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。

          <br /><br /> 2. 我们如何使用Cookie和同类技术
          <br />2.1 Cookie是支持服务器端 (或者脚本)在客户端上存储和检索信息的一种机制。泽诚数字大宗暂未使用Cookie或同类技术来收集和存储您的信息。

          <br /><br /> 3. 我们如何存储和保护您的个人信息
          <br /> 3.1 信息保存期限
          <br /> 3.1.1 在用户使用泽诚数字大宗平台服务期间，泽诚数字大宗会持续保存用户的个人信息。
          <br /> 3.1.2
          当您需要删除个人信息或注销账户，您可自主联系泽诚数字大宗客服。泽诚数字大宗将按照法律、法规规定的为提供我们的服务之目的所必需的最短期限内保存您的个人信息。但您要求删除个人信息或注销账户的、或法律法规另有规定的除外，当您的个人信息超出上述保存期限，泽诚数字大宗将根据法律法规的要求尽快删除您的个人信息或作匿名化处理。
          <br /> 3.1.3 为配合人民检察院、公安机关、国家安全机关侦查用户使用泽诚数字大宗服务过程中产生的犯
          罪行为、更好地保护其他用户的财产安全，当用户删除个人信息或注销账户时，泽诚数字大宗将在刑法规定的犯罪追诉时效期间内，加密隔离存档用户个人信息。
          <br /> 3.1.4 如您使用泽诚数字大宗平台服务的过程中，存在严重违反法律法规、平台协议、平台政策或规则等情形，您的违法、违约记录及相应的平台信用记录、个人信息，将被永久保存。
          <br /> 3.2 信息存放地域
          <br /> 3.2.1 泽诚数字大宗收集的您的个人信息，将在中国内地存储和使用。
          <br /> 3.2.2 目前泽诚数字大宗不存在向境外提供个人信息的场景。如将来涉及向境外传输个人信息，泽诚数字大宗将明确向您告知个人信息出境的目的、接收方、安全保障措施等情况，并另行征得您的同意。
          <br /> 3.3 安全保护措施
          <br /> 3.3.1 泽诚数字大宗仅为实现本政策所述目的保留您的个人信息。
          <br /> 3.3.2 泽诚数字大宗会采用符合业界标准的安全防护措施，通过建立数据全生命周期的安全管理规范
          和相应的技术管控措施并使用相应的安全技术和管理程序来保护您的个人数据，在任何时候尽力做到使您的个人信息不被泄露、毁损、丢失、不当使用、未经授权阅览或披露
          <br /> 3.3.3 泽诚数字大宗采取以下安全防护措施保护您的个人信息:
          <br /> (1) 建立针对个人数据安全的纵深防御体系，数据从创建、存储、使用、传输、归档、销毁 各环节上均采用管理与技术的双重控制措施，避免集中性的数据操作风险。
          <br />(2) 用户个人敏感信息被加密储并通过数据隔离措施降低数据的重标识风险。
          <br />(3) 使用加密传输协议，保障数据传输安全。
          <br />(4) 严格控制数据访问权限，设立完善的敏感数据访问权限丰请、审批制度。
          <br />(5) 建立数据安全监控和安全审计，进行全面数据安全控制。
          <br /> 3.3.4 泽诚数字大宗同时采取其他安全措施保护您的个人信息:
          <br /> (1) 建立公司信息安全委员会，统一协调管理数据安全工作并下设数据安全小组，推动各项数据安全活动。
          <br />(2) 公司内部颁布实施数据安全管理规范，明确对用户数据(包括用户个人信息)的保护标准和要求。
          <br />(3) 新项目、新系统上线前对数据安全 (包括用户个人信息数据)进行项目风险评估。
          <br /> (4) 与全体员工签署保密协议，并严格按照工作职责分配数据访问权限。
          <br /> (5) 定期开展面向全体员工的信息安全教育及培训。
          <br /><br /> 3.4 安全事件处置
          <br /> 3.4.1 为应对个人信息泄露、毁损、丢失等可能出现的安全风险，已经在公司范围内颁布多项制度，明确了安全事故、安全漏洞的分类分级标准，以及针对上述安全事故和漏洞的内部 处理流程和补救措施。
          <br /> 3.4.2 一旦发生安全事件，泽诚数字大宗将及时向您告知事件基本情况和风险、泽诚数字大宗已经采取或将要采取的措施、您如何自行降低风险的建议等。
          <br /> 3.4.3
          泽诚数字大宗将及时以推送通知、信函或电话等方式将安全事件情况通知受影响的用户。当难以逐一通知用户时，泽诚数字大宗将通过发布平台公告的方式发布警示信息。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
          <br /><br /> 3.5 停止运营
          <br /> 3.5.1 如泽诚数字大宗平台停止运营，泽诚数字大宗将至少提前30日在泽诚数字大宗平台发布公告，并及时停止收集个人信息。
          <br /> 3.5.2 停止运营后，泽诚数字大宗将停止对个人信息的商业化使用并在满足法律法规规定的最短保存期后，对收集的个人信息进行匿名化处理。
          <br /><br /> 3.6 免责条款
          <br /> 3.6.1
          您同意并理解，即便竭尽所能加强安全防护措施，由于黑客攻击、技术限制、可能存在的各种恶意手段及/或其他因泽诚数字大宗可控范围外的因素，泽诚数字大宗所获得及保存的您的个人信息有可能在未经您许可的情况下被其他第三方获得，在这种情况下，除非泽诚数字大宗故意泄露您的该等信息，您不应当追究泽诚数字大宗的责任。
          <br /> 3.6.2
          在使用泽诚数字大宗平台进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，否则对因此而造成的您的个人信息的泄露和损失，泽诚数字大宗不承担任何责任。
          如您发现自己的个人信息泄密，尤其是账户及/或其密码发生泄露，请您立即联终泽诚数字大宗客服，以便泽诚数字大宗采取相应措施。
          <br /> 3.6.3 泽诚数字大宗将根据法律法规规定及本政策约定对您的个人信息予以保密，但下列情形除外:
          <br />(1) 政府机关、司法机关及监管部门提出要求;
          <br />(2) 相关信息已成为公开信息;
          <br />(3) 您同意无须再严格保密;
          <br />(4) 本政策另有约定;
          <br />(5) 其他合法情形。

          <br /><br />4. 我们如何使用您的个人信息
          <br /> 4.1 您的个人信息会被用于本政策第1条“我们如何收集您的个人信息”条款明确列明的使用场景。
          <br /> 4.2 您的个人信息可能被用于以下与“我们如何收集您的个人信息”条款所声称的目的具有 直接或合理关联的场景:
          <br />4.2.1
          在泽诚数字大宗提供服务时，用于身份验证、用户服务、安全防范、诈骗监测、存档和备份用途，以提高泽诚数字大宗向您提供服务的安全性;用于预防、发现、调查欺诈以及危害安全非法或违反与泽诚数字大宗签订的平台协议、泽诚数字大宗平台政策或规则的行为，以保护您、其他泽诚数字大宗用户、泽诚数字大宗及其他善意第三方的合法权益;
          <br /> 4.2.2 泽诚数字大宗可能利用个人信息统计数据为基础设计、开发推广全新的产品及服务，或用于改善泽诚数字大宗平台现有服务;
          <br /> 4.2.3
          泽诚数字大宗会自行或委托第三方向您发送信息的通知，包括但不限于为保证服务完成所必需的验证信息、使用产品或服务时所必要的推送通知、服务状态通知、关于泽诚数字大宗平台的新闻、营销活动及其他商业性电子信息、泽诚数字大宗合作第三方的推广信息，或其他您可能感兴趣的内容。
          如您不希望继续接收上述信息，可以根据信息中提供的退订方式予以退订;
          <br /> 4.2.4 泽诚数字大宗可能会对泽诚数字大宗平台服务进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的身份识别信息;
          <br /> 4.2.5
          为了让您有更好的体验、改善泽诚数字大宗平台服务或您同意的其他个人信息用途，在符合相关法律法规的前提下，泽诚数字大宗可能将通过某一项泽诚数字大宗平台服务所收集的信息，以汇集信息或者个性化的方式，用于泽诚数字大宗的其他服务或者与泽诚数字大宗平台合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。
          <br />4.3 您充分知晓，泽诚数字大宗收集、使用、向第三方提供以下信息无需征得您的授权同意:
          <br /> 4.3.1 与泽诚数字大宗履行法律法规规定的义务相关的;
          <br />4.3.2 与国家安全、国防安全有关的;
          <br />4.3.3 与公共安全、公共卫生、重大公共利益有关的;
          <br />4.3.4 与犯罪侦查、起诉、审判和判决执行等有关的;
          <br />4.3.5 出于维护您或其他用户的生命、财产等重大合法权益但又很难得到您本人同意的;
          <br />4.3.6 您自行向社会公众公开的您的个人信息;
          <br />4.3.7 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道;
          <br /> 4.3.8 根据您的要求签订和履行合同所必需的信息;
          <br /> 4.3.9 用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障;
          <br />4.3.10 为合法的新闻报道所必需的;
          <br />4.3.11 国家机关、事业单位、学术研究机构或该等单位授权的主体基于公共利益所必要或基于公共利益开展统计或学术研究所必要，且对外提供研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的;
          <br />4.3.12 您在使用泽诚数字大宗平台服务时输入的其他用户或除本政策以外的第三方可在泽诚数字大宗平台上看见或知悉的信息;
          <br />4.3.13 泽诚数字大宗收集到的您在泽诚数字大宗平台发布的有关信息数据，包括但不限于货源信息、交易信息、参与活动、成交信息及评价详情;
          <br /> 4.3.14 您的信用评价信息、您违反法律规定、《泽诚数字大宗用户服务协议》及/或泽诚数字大宗平台的其他规则向泽诚数字大宗平台提交的个人信息、实施的行为;
          <br /> 4.3.15 其他用户对您的投诉、举报信息;
          <br /> 4.4 凡是超出本政策第1条“我们如何收集您的个人信息”条款声称目的以及本条所述范围使用您的个人信息，泽诚数字大宗将再次向您告知并征得您的明示同意。
          <br /><br /> 5. 我们如何共享、转让、公开披露您的个人信息

          <br /> 5.1 共享
          <br /> 5.1.1 除以下情形外和本政策另有约定外，未经您同意，泽诚数字大宗不会与任何第三方分享您的个人信息:
          <br /> (1) 实现本政策第1条所述为实现泽诚数字大宗基本业务功能必须收集的信息所述目的，只有共享您的个人信息，才能提供您所需要的服务;
          <br /> (2)
          为便于平台上的其他用户通过您提供的手机号码与您联系，同时严肃交易秩序、建立交易双方的信任感、保障交易的真实性，提升平台信息撮合的成功率，我们会将您的手机号、真实姓名、头像和基站定位信息向平台上的其他用户展示。如您不同意向平台上的其他用户
          展示您的真实姓名、头像和基站定位信息，您可以在【个人中心】更改您的头像。
          <br /> (3) 为促成并实现物流信息交换和在线运输交易功能，将您的姓名联系方式等信息向您的交易对方
          (其他司机/货主用户)分享，在泽诚数字大宗平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，泽诚数字大宗有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决;
          <br /> (4) 处理您与他人的纠纷或争议 (基于法定情形下:根据法律法规的规定、诉讼争议解决需要，或行政、司法等有权机关依法提出的要求) ;
          <br /> (5) 为了判断您的账户或交易是否安全;
          <br /> (6) 为遵守适用的法律法规、维护社会公共利益，或保护泽诚数字大宗、泽诚数字大宗的用户、雇员的人身、 财产安全或其他合法权益所必须使用的合理用途;
          <br /> (7) 您出现违反有关法律规定或者《泽诚数字大宗用户服务协议》或平台规则的情况，需要向第三方披露您的个人信息;
          <br /> (8) 根据法律规定、行政监管、刑事侦查机关为调查犯罪，依法定程序调取的必要个人信息， 或行政机关、司机机构依法定程序调取的必要个人信息;
          <br /> (9)
          履行泽诚数字大宗在《泽诚数字大宗用户服务协议》或本政策中的义务和行使泽诚数字大宗的权利，以及其他泽诚数字大宗根据法律规定、《泽诚数字大宗用户服务协议》及/或泽诚数字大宗平台的其他规则认为有必要披露的情形。
          <br /> 5.1.2
          在本政策第5.1条所述情形下，泽诚数字大宗可能将您的个人信息与泽诚数字大宗的关联公司分享，您同意泽诚数字大宗的关联公司在获取您的个人信息后，为本政策第5.1条所述的目的或用途，泽诚数字大宗的关联公司可向第三方分享您的个人信息。
          <br /> 5.1.3 泽诚数字大宗可能将个人信息统计数据、脱敏数据及/或加密数据提供给泽诚数字大宗的合作方，用于进行产品测试、建造风控模型、基础设计、开发、推广全新的产品及服务，或用于改善泽诚数字大宗平台现有服务;
          <br /> 5.1.4 除本政策约定的泽诚数字大宗将您的个人信息提供给第三方的情形以外，如基于其他情况泽诚数字大宗 需将您的个人信息提供给第三方，泽诚数字大宗将按照法律法规的要求以确认协议、具体场景下的文
          案确认动作等形式另行征得您的同意。
          <br /> 5.1.5 给任何第三方机构或个人。对泽诚数字大宗与之共享个人信息的公司、组织和个人，泽诚数字大宗会努力与之签署保密协议，要求他们按照泽诚数字大宗的说明、本政策及其他任何相关的保密和安全措施来处理 个人信息。
          <br /> 5.2
          转让随着泽诚数字大宗业务的持续发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易等变更，您同意授权泽诚数字大宗有权将您的个人信息作为此类交易的一部分而被转移。如涉及个人信息的转让，我们会要求受让您个人信息的公司、组织继续接受本隐私政策的约束，否则,我们将要求该公司、组织重新征求您的授权同意。
          <br /> 5.3 公开披露
          <br /> 我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才可能公开披露您 的个人信息:
          <br /> 5.3.1 根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息;
          <br /> 5.3.2 根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律
          法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。
          <br /> 5.4 共享、转让、公开披露个人信息时事先征得授权同意的例外
          以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意:
          <br /> 5.4.1 与泽诚数字大宗履行法律法规规定的义务相关的;
          <br /> 5.4.2 与国家安全、国防安全有关的;
          <br /> 5.4.3 与公共安全、公共卫生、重大公共利益有关的;
          <br /> 5.4.4 与犯罪侦查、起诉、审判和判决执行等有关的;
          <br /> 5.4.5 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;
          <br /> 5.4.6 其他维护公共利益的情形，例如您的信用评价信息需要被公开\共享;
          <br /> 5.4.7 您自行向社会公众公开的个人信息;
          <br /> 5.4.8 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
          <br /> 5.4.9 根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向您通知并征得您的同意。请注意:
          您在使用我们服务时自愿发布甚至公开分享的信息，可能会涉及您或
          他人的个人信息甚至个人敏感信息，如您的交易信息，以及您在评价时选择上传包含个人信息的文字、图片或视频等各种形式的信息。请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。

          <br /> <br />6. 您的权利
          <br /> 6.1 访问、更正、删除您的个人信息
          <br /> 6.1.1 账户信息: 您可以通过您在泽诚数字大宗平台的[“设置”-“个人资料”] 页面访问、更正您提供的个人资料信息，也可以更改您的密码等。
          <br /> 6.2 响应您的上述请求
          <br /> 6.2.1 如您无法通过上述方式访问、更正、删除个人信息，您可以随时联系泽诚数字大宗客服。泽诚数字大宗客服可能需要验证您的身份，并在验证您的身份后30日内作出答复或合理解释。
          <br /> 6.2.2 对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段 (例如，需要开发新系统或从根本上改变现行惯例)
          、给他人合法权益带来风险或者非常不切实际 (例如，涉及 备份磁带上存放的信息)的请求，我们可能会予以拒绝。
          <br /> 6.2.3 在以下情形中，按照法律法规要求，我们将无法响应您的请求:
          <br /> (1) 与国家安全、国防安全直接相关的;
          <br /> (2) 与公共安全、公共卫生、重大公共利益直接相关的;
          <br /> (3) 与犯罪侦查、起诉、审判和判决执行等直接相关的;4)有充分证据表明您存在主观恶意或滥用权利的;
          <br /> (4) 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
          <br /> (5) 涉及商业秘密的

          <br /><br /> 7. 本政策的更新
          <br /> 7.1 如泽诚数字大宗平台发生以下变化，泽诚数字大宗将及时对本政策进行相应的修订:
          <br /> 7.1.1
          本政策生效后，本政策代替泽诚数字大宗之前发布的或您之前签署、确认的《泽诚数字大宗用户隐私权保护协议》;本政策生效前，仍按《泽诚数字大宗用户隐私权保护协议》执行，户隐私权保护协议》中未尽的事宜，按本政策执行。
          <br /> 7.1.2 泽诚数字大宗平台业务功能发生变更
          <br />7.1.3 用户个人信息保存地域发生变更
          <br /> 7.1.4 用户个人信息的收集、使用规则发生变更
          <br /> 7.1.5 泽诚数字大宗的联络方式及投诉渠道发生变更
          <br /> 7.1.6 发生其他可能影响用户个人信息安全或影响用户隐私权利的变更等,
          <br /><br /> 7.2
          本政策修订后，泽诚数字大宗会在泽诚数字大宗平台发布最新版本并以推送通知的方式告知您，以便您及时了解最新版本的本政策，如无特殊说明，修订后的协议自公布之日起7日后生效。若您在本政策的内容发生修改、变更后，继续使用泽诚数字大宗平台服务的，视为您已阅读、了解并同意最新修订的本政策内容。

        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showyinsi = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="《泽诚数字大宗加用户服务协议》" :visible.sync="showxieyi" width="30%" append-to-body>
      <div class="text-content">
        <p class="title">《泽诚数字大宗加用户服务协议》</p>
        <p>版本及更新</p>
        <p>当前版本：2023年4月版</p>
        <p>
          历史版本：
          <span class="text-orange"> 无</span>
        </p>
        <div class="textarea">
          尊敬的泽诚数字大宗加平台用户（以下简称“您”或“用户”），欢迎您使用陕西泽诚数字科技有限公司 （及其关联公司）（简称“我们”或“泽诚数科”）提供的产品和服务。
        </div>
        <div class="textarea">
          本协议自发布之日起即刻生效。本协议各条款前“【 】”所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
        </div>
        <div class="textarea">
          【审慎阅读】如果：您勾选"我已阅读并同意《泽诚数科用户服务协议》《隐私保护政策》"按钮，或者您完成注册程序取得泽诚数字大宗加平台账号，或者您完成企业认证服务，或者您通过泽诚数科旗下的网站、应用程序（APP）、小程序、子平台等方式同意和签署本协议的，即表示您已阅读并充分理解本协议的全部内容，与我们达成一致，成为泽诚数字大宗加平台的注册用户，并自愿遵守本协议。
        </div>
        <div class="textarea">
          【审慎阅读】请您在注册之前务必审慎阅读本协议各条款，特别是免除或限制责任条款、法律适用条款和管辖条款。免除或限制责任条款、法律适用条款和管辖条款将以下划线或粗体标识，请您重点阅读。如果您对本协议条款有疑问，请通过泽诚数科客服进行询问，我们将向您解释条款内容。如果您不接受本协议或本协议任一条款，请不要继续操作。
        </div>
        <div class="textarea">
          第一条 定义
        </div>
        <div class="textarea">
          1.1 泽诚数科：指泽诚数字大宗加平台（定义见1.2）的经营者或服务提供者的单称或合称，包括但不限于下列主体：
        </div>
        <div class="textarea">
          陕西泽诚数字科技有限公司及其关联公司等。
        </div>
        <div class="textarea">
          1.2
          泽诚数字大宗加平台：指泽诚数科官网、泽诚数科子平台、移动客户端(APP)、公众号、小程序、视频号等（包括未来技术发展出现的新的服务形态）用于提供服务的程序、系统、应用、网站、客户端及相应设备。泽诚数字大宗加平台是我们经营管理的大宗商品数字供应链科技服务平台，为平台内的企业用户提供平台服务或产品。
        </div>
        <div class="textarea">
          1.3
          泽诚数字大宗加平台服务：指泽诚数科通过泽诚数字大宗加平台为平台用户提供线上或线下的各种服务，包括但不限于推荐服务、交易数字化服务、电子证书服务、信息技术支持服务、数字风控服务等，具体服务内容以我们和用户订立的泽诚数科用户服务协议内容为准。
        </div>
        <div class="textarea">
          第二条 协议范围
        </div>
        <div class="textarea">
          2.1 本协议是您与陕西泽诚数字科技有限公司之间就泽诚数字大宗加平台服务等相关事宜所订立的契约，本协议阐述之条款和条件适用于泽诚数字大宗加平台向您提供的各项产品或服务（包括未来技术发展出现的新的服务形态）。
        </div>
        <div class="textarea">
          2.2
          【子公司和/或关联法律实体】泽诚数科可能在不同地区、不同业务领域拥有子公司和/或关联法律主体。在某些时候，这些子公司和/或关联法律主体将代表泽诚数科向您提供服务。您理解并同意这些子公司和/或关联法律主体有权向您提供服务。
        </div>
        <div class="textarea">
          2.3
          【主体变更】泽诚数科可能根据泽诚数科业务调整将本协议项下权利义务转让给第三方。泽诚数科完成前述转让后，权利义务受让方会与您共同履行本协议并向您提供服务，这种情况不会影响您本协议项下权益。对于泽诚数科的本协议所在权利义务转让，泽诚数科将以站内公告的方式通知您，并在公告中载明的日期开始由变更后的主体为您提供服务。公告期内，如用户对公告内容有疑问，可致电我们进行咨询。在公告期内，用户有权选择是否接受，如不接受应在公告载明的实施日期前终止使用，否则视为用户接受该变更，变更后的协议对用户具有法律效力。
        </div>
        <div class="textarea">
          2.4
          【补充协议】您理解并同意，您与泽诚数科签署的本协议列明的条款并不能完整罗列并覆盖您与泽诚数科所有权利与义务，泽诚数科会另行与您签署业务条款。例如，当您作为具有融资服务需求、数字证书服务需求的企业或其雇员、代理人使用泽诚数字大宗加平台服务时，您可能需要符合相应的准入条件，并签署相应的业务协议或授权文件。业务协议与本协议不可分割且具有同等法律效力。
        </div>
        <div class="textarea">
          2.5
          【审慎阅读】您同意，泽诚数科有权在必要时修改本协议条款并通过泽诚数字大宗加平台的网站公告或消息推送等方式提前予以公布，您可以在相关服务页面查阅最新版本的协议条款和历史版本的协议条款。如您对公告内容有疑问，可致电我们进行咨询。若您不接受相关修改或调整，应在公告施行前终止使用。若您继续使用泽诚数字大宗加平台服务的，表示您接受变更后的协议，也将遵循变更后的协议使用泽诚数字大宗加平台服务。
        </div>
        <div class="textarea">
          2.6 您同意并理解，在泽诚数字大宗加平台内的具体产品/服务由其他服务提供方为您提供时，在法律规定和约定的范围内由各服务提供方自行与您就相应产品/服务享有权利，承担责任和义务。
        </div>
        <div class="textarea">
          第三条 用户资格
        </div>
        <div class="textarea">
          3.1 泽诚数字大宗加平台的运营权归泽诚数科所有。当您完成注册程序取得泽诚数字大宗加平台账号时，即表示您已充分阅读、理解、接受并同意本协议的全部内容，并与泽诚数科达成一致，成为泽诚数字大宗加平台用户。
        </div>
        <div class="textarea">
          3.2
          【非自然人法律主体】如果您属于非自然人法律主体，则您须为在中国大陆地区合法设立并已具备开展经营活动相应资质的法人或其他组织，且您订立并履行本协议是您的真实意思表示，不受您所属、所居住或开展经营活动或其他业务的国家或地区法律法规的限制，不会与您的现行章程和内部规章或对您具有约束力的任何合同、协议及其他文件相抵触。如果您不具备前述条件的，您应立即停止注册，否则您应依据法律规定承担因此导致的不利后果。
        </div>
        <div class="textarea">
          3.3 【非限制、制裁对象】如果您属于任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，则您应立即停止注册，否则您应依据法律规定承担因此导致的不利后果。
        </div>
        <div class="textarea">
          3.4
          【非无行为能力或限制行为能力人】如您为无民事行为能力人或为限制民事行为能力人，您不符合泽诚数字大宗加平台用户的资格，泽诚数科将拒绝为您开通账号或提供服务。如果您通过隐瞒、欺骗等方式取得了泽诚数字大宗加平台的账号或使用了泽诚数字大宗加平台的服务，泽诚数科在获知您为无民事行为能力人或为限制行为能力人之日起，有权立即冻结、停止、注销您的账号，且因您的隐瞒、欺骗行为导致的相关法律后果由您或您的法定监护人承担。
        </div>
        <div class="textarea">
          第四条 账号说明
        </div>
        <div class="textarea">
          4.1
          【账号取得】您可以创建自己的泽诚数字大宗加平台账号，也有可能通过管理者（如您所在的单位、企业）的分配取得泽诚数字大宗加平台账号。在后一种情况下，除了接受或同意本协议，您可能需要遵守管理者另外制定的条款或附加条款，并且您的管理者可能有权访问或停用、冻结您的账号。
        </div>
        <div class="textarea">
          4.2 【用户名合法保证】您设置的泽诚数字大宗加平台用户名不得违反国家法律法规及泽诚数科关于用户名的管理规定，不符合要求的用户名是不能注册的。（在不损害泽诚数科任何权益前提下）。
        </div>
        <div class="textarea">
          4.3
          【账号数量限制】通常情况下，泽诚数字大宗加平台只允许每位用户使用一个泽诚数字大宗加平台账号。如有证据证明或泽诚数科有理由相信您存在不当注册或不当使用多个泽诚数字大宗加平台账号的情形，泽诚数科可采取冻结或关闭账号、拒绝提供服务等措施，如给泽诚数科及相关方造成损失的，您还应承担赔偿责任。
        </div>
        <div class="textarea">
          4.4
          【账号权限】由于泽诚数字大宗加平台有不同的业务服务领域，各领域有不同的准入条件和业务条款，所以您取得泽诚数字大宗加平台账号并不意味着您能使用泽诚数字大宗加平台的所有产品或者服务。您应当满足相应领域的准入条件（如实名认证、企业/单位认证、资质认证、授权认证、开通数字证书等）并签署相应的业务条款后，您才能使用相应的产品或服务。
        </div>
        <div class="textarea">
          4.5
          【手机号码/邮箱号码】您理解并同意，为了您更便捷地使用泽诚数字大宗加平台服务，并保障您的泽诚数字大宗加平台账号安全，您需要将您的手机号码/邮箱号码和您的泽诚数字大宗加平台账号绑定。通常情况下，一个手机号码/邮箱号码只允许绑定一个泽诚数字大宗加平台账号，因此，若您的手机号码/邮箱号码发生了变化，请您及时登陆泽诚数字大宗加平台账号变更您的手机号码/邮箱号码，以保证您能正常使用泽诚数字大宗加平台账号，从而使用泽诚数字大宗加平台服务。手机号码/邮箱号码是您登陆泽诚数字大宗加平台账号的一个非常重要的登陆手段，因此，泽诚数科建议您务必保管好您的通讯设备和个人相关信息安全。
        </div>
        <div class="textarea">
          第五条 账号注册
        </div>
        <div class="textarea">
          5.1
          【注册信息真实】您在注册或使用泽诚数字大宗加平台时可能需要提供一些必要的信息。您须保证所填写的注册表和所提供的资料真实、准确、完整、合法有效，否则在使用过程中受到限制。注册资料如有变动的，应及时更新您的注册资料，否则您需承担因此引起的相应责任及后果，并且泽诚数科保留终止您使用泽诚数字大宗加平台各项服务的权利。对于因您提供的信息不真实、不准确或不完整导致的损失由您承担。
        </div>
        <div class="textarea">
          5.2
          【注册成功与否】泽诚数科有权根据您所提供注册资料的真实性、准确性、完整性以及是否符合泽诚数字大宗加平台规定的其他条件，决定您的注册成功与否。如您提供的注册资料存在虚假信息、或冒用他人信息的情况，泽诚数科有权注销该账号。
        </div>
        <div class="textarea">
          5.3
          【补充信息使用】鉴于泽诚数字大宗加平台项下包含不同的子网站及产品/服务，为客户体验及功能服务考虑，您在不同平台入口注册或产品开通时被要求填写的内容可能不完全一致，因此，在使用更高级别服务时，为了给您提供更好的服务及为了您的账号安全，可能需要您补充完整信息后方可使用。
        </div>
        <div class="textarea">
          5.4
          【用户名和密码安全】您须妥善保管本人的用户名和密码，及装载和使用泽诚数字大宗加平台服务的设备。凡使用该用户名和密码的行为，泽诚数字大宗加平台视为您本人及授权您本人开通该账号的单位/企业的操作，操作所产生的电子信息记录均为泽诚数字大宗加平台用户行为的有效凭据。您对利用该用户名和密码所进行的活动承担全部责任。您应按照机密的原则设置和保管密码，包括但不限于：
        </div>
        <div class="textarea">
          （1）避免使用生日、电话号码等与本人明显相关的数字作为密码；
        </div>
        <div class="textarea">
          （2）登录泽诚数字大宗加平台尽快重新设置密码；
        </div>
        <div class="textarea">
          （3）应定期更换密码并牢记；
        </div>
        <div class="textarea">
          （4）在设备上输入密码时，应防止旁人或不安全设备窥视；
        </div>
        <div class="textarea">
          （5）不应在网吧等公众场所的或他人的电脑或网络设备上使用泽诚数字大宗加平台账号；
        </div>
        <div class="textarea">
          （6）结束使用泽诚数字大宗加平台服务时，应选择安全退出方式终止当前服务；
        </div>
        <div class="textarea">
          （7）发现密码泄漏、遗忘，应及时办理密码重置手续。
        </div>
        <div class="textarea">
          5.5 【账号遭窃、遗失通知】
          如果发现任何未经授权使用您账号登录泽诚数字大宗加平台或其他可能导致您账号遭窃、遗失的情况，建议您立即联系泽诚数科客服，联系方式见泽诚数科官方网站的客服电话或联系电话、联系邮箱等方式。您理解泽诚数科对您的任何请求采取行动均需要合理时间，除泽诚数科存在过错外，泽诚数科对因任何未经授权使用您账号登录泽诚数字大宗加平台或其他可能导致您账号遭窃、遗失的情况而产生的后果不承担任何责任。账号因您泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，泽诚数科并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。
        </div>
        <div class="textarea">
          5.6 【密码找回】您遗忘或丢失在泽诚数字大宗加平台注册的密码时，可通过注册手机或邮箱的验证码找回密码，也可与泽诚数科客服人员取得联系，在提供相关证明资料并经泽诚数科审核确认后，可找回密码。
        </div>
        <div class="textarea">
          5.7 【证据保全】您同意泽诚数科有权在征得您的同意后或特殊情况下（如司法保全、执法调查等）使用您的注册信息、密码等信息，登陆进入您的注册账号，进行证据保全，包括但不限于公证、见证等。
        </div>
        <div class="textarea">
          5.8
          【信息核查核实】在法律有明确规定要求泽诚数科作为服务提供者必须对用户信息进行核实的情况下，泽诚数科将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、准确的信息。如果泽诚数科按您最后一次提供的信息与您联系未果，或您未按泽诚数科的要求及时提供信息，或您提供的信息存在明显不实的，您将承担因此对您自身、他人及泽诚数科造成的全部损失与不利后果。
        </div>
        <div class="textarea">
          第六条 账号管理
        </div>
        <div class="textarea">
          6.1
          【认证企业账号】为享有包括在线交易、电子签章（数字证书）、线上合同、物流跟踪管理、供应链金融、结算管理、货物质数量验收等更多的企业服务和平台操作功能，您可以申请认证为企业账号。您应按照泽诚数科企业账号认证要求提供授权书、营业执照以及其他所需资料，且经泽诚数科审核通过后，您即为泽诚数科认证后的企业账号。由于泽诚数科目前只针对企业等非自然人提供产品或服务，如果您未申请企业账号的认证，则您无法享受泽诚数字大宗加平台的相关企业服务或产品，但您仍可以浏览、查看泽诚数科针对非企业账号提供的相关服务或产品。
        </div>
        <div class="textarea">
          6.2 【不活跃账号回收】如果您的账号同时符合以下条件，则泽诚数科可回收您的账号，您的账号将不能再登录泽诚数字大宗加平台，相应服务同时终止：
        </div>
        <div class="textarea">
          （一）未按照约定进行实名认证和/或资质认证的；
        </div>
        <div class="textarea">
          （二）连续一年未用于登录任一泽诚数字大宗加平台；
        </div>
        <div class="textarea">
          （三）不存在未到期的有效业务。
        </div>
        <div class="textarea">
          第七条 账号注销
        </div>
        <div class="textarea">
          7.1
          【审慎阅读】泽诚数字大宗加平台为您提供账号注销功能，您可以通过泽诚数科官网公布的联系方式联系泽诚数科，按照泽诚数字大宗加平台注销流程注销泽诚数字大宗加平台账号。由于您的泽诚数字大宗加平台账号可能直接关系到您是否能正常使用泽诚数字大宗加平台服务，且泽诚数字大宗加平台账号注销属于不可恢复的操作，因此，为了保证您的泽诚数字大宗加平台账号安全以及与泽诚数字大宗加平台账号相关的所有服务均已经进行了妥善处理，在您向泽诚数科提出注销泽诚数字大宗加平台账号申请之后，泽诚数科会就您的泽诚数字大宗加平台账号进行相应核查及身份核验。
        </div>
        <div class="textarea">
          7.2
          【注销账号后果】泽诚数科在此善意的提醒您，您注销账号的行为会给您的业务操作、后续维权等事宜带来诸多不便，且注销账号后，您的用户信息泽诚数科只会在泽诚数字大宗加平台前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓，根据法律法规规定，相关交易记录会在平台后台保存3年甚至更长时间。
        </div>
        <div class="textarea">
          账号注销前用户需确认账号不存在的情形如下：
        </div>
        <div class="textarea">
          （1）账号无任何纠纷，包括投诉举报和被投诉举报；
        </div>
        <div class="textarea">
          （2）账号为正常使用中的账号且无任何账号被限制的记录；
        </div>
        <div class="textarea">
          （3）账号内无未完成的订单、服务；
        </div>
        <div class="textarea">
          （4）账号已经解除与其他网站、其他APP的授权登录或绑定关系。
        </div>
        <div class="textarea">
          一旦您的账号注销，您将无法登录、使用本平台账号，您也将无法找回您账号中及与账号相关的任何内容或信息，在您的注册期间所对应的权利也将不复存在。
        </div>
        <div class="textarea">
          第八条 服务内容
        </div>
        <div class="textarea">
          8.1
          您理解并同意，泽诚数科提供的服务内容众多，服务范围非常广泛，您取得泽诚数字大宗加平台账号后不必然享有泽诚数字大宗加平台公布的所有产品或服务，除非您满足该产品或服务的准入条件，并/或签署相应的服务协议，具体您可登录泽诚数字大宗加平台浏览。
        </div>
        <div class="textarea">
          8.2
          【服务承诺免责】您理解并同意，泽诚数科在提供服务时将会尽到商业上合理水平的技能和注意义务，但泽诚数字大宗加平台公布信息不意味着对服务承诺，具体服务内容以您实际签署的相关协议为准。除本协议或补充协议中明确规定的内容外，泽诚数科及其供应商对服务均不作任何具体承诺。
        </div>
        <div class="textarea">
          8.3
          【不可抗力免责】泽诚数科负责"按现状"和"可得到"的状态向您提供服务。泽诚数科依法律规定承担基础保障义务，但无法对由于信息网络设备维护、连接故障，电脑、通讯或其他系统的故障、电力故障、罢工、暴乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令或因第三方原因而给您造成的损害结果承担责任。
        </div>
        <div class="textarea">
          8.4
          【服务费用】泽诚数科为向您提供的泽诚数字大宗加平台服务付出了大量的成本，除泽诚数字大宗加平台明示的免费业务或另有单独协议约定外，泽诚数科向您提供的服务目前是收费的，具体收费标准以您与泽诚数科签订的相关泽诚数科用户服务协议约定为准。
        </div>
        <div class="textarea">
          第九条 用户使用规范
        </div>
        <div class="textarea">
          9.1 您在使用泽诚数字大宗加平台服务过程中实施的所有行为均应遵守国家法律、法规，不得违背社会公共利益或公共道德，不得损害他人的合法权益，不违反本协议及相关规则。如果您违反前述承诺，产生任何法律后果由您自行承担责任。
        </div>
        <div class="textarea">
          9.2 您承诺在交易过程中遵守诚实信用原则，不采取不正当竞争行为，不扰乱泽诚数字大宗加平台的正常秩序，不得利用泽诚数字大宗加平台进行非法牟利活动。
        </div>
        <div class="textarea">
          9.3 除泽诚数科同意外，不得对泽诚数科上的任何数据进行商业性利用，包括但不限于以复制、传播、爬虫抓取等任何方式使用泽诚数字大宗加平台上展示的信息。
        </div>
        <div class="textarea">
          9.4 您承诺不使用任何装置、软件或例行程序干预或试图干预泽诚数字大宗加平台的正常运作或正在进行的任何交易、活动。
        </div>
        <div class="textarea">
          第十条 生物特征信息识别服务规范
        </div>
        <div class="textarea">
          10.1
          【生物特征信息识别】泽诚数科将使用人脸信息比对、声纹特征、指纹特征等手段来确认您本人的身份，您同意直接向泽诚数科或泽诚数科委托的其他信息识别服务商提供您的个人身份信息（姓名、身份证件号码、身份证件照片）、面部照片或认证视频、认证语音信息、指纹特征等生物特征信息，以便以前述方式来核验您的身份。
        </div>
        <div class="textarea">
          10.2
          【其他信息识别服务商】某些场景下，泽诚数科需由其他信息识别服务商协助泽诚数科完成您的信息识别服务。您同意并授权，为完成信息识别服务，泽诚数科可以向页面提示/展示的信息识别服务商提供您的姓名、身份证件号码、面部照片等信息，以便完成信息识别服务。需要泽诚数科特别提醒的是，在该种场景下，需要您仔细阅读该信息识别服务商展示的《身份认证服务协议》，如您不认可前述协议内容，请勿勾选同意该服务商相关协议的选项或请您直接拒绝该服务商提供的服务，以免该服务商误以为您同意前述服务协议。
        </div>
        <div class="textarea">
          10.3
          【用户信息储存】为了准确验证您的身份，您同意泽诚数科对您的人脸照片或认证视频信息、认证语音信息等做必要的处理（包括保存摘要信息、比对等行为）。您同意并授权我们将您向我们提供的人脸信息、身份信息与合法存有您前述信息的机构（包括公安部）进行比对核验。需要泽诚数科特别提醒的是，在其他信息识别服务商协助泽诚数科完成您的信息识别服务的场景下，其他信息识别服务商为准确验证您的身份，可能存储您的个人信息，具体以该服务商展示的《身份认证服务协议》为准。如您拒绝该服务商储存您的个人信息，请勿勾选同意该服务商相关协议的选项或请您直接拒绝该服务商提供的服务，以免该服务商误以为其有权储存您的个人信息。
        </div>
        <div class="textarea">
          10.4 【用户信息识别结果】
          在泽诚数科或其他信息识别服务商完成您的信息识别服务后，您可通过泽诚数科官网、APP、小程序端等渠道查看您的用户信息识别结果，泽诚数科亦有权通过用户信息识别结果来判断您的身份。如您授权其他第三方调取泽诚数科的认证结果，泽诚数科将按您的授权将认证结果提供给其他第三方。您理解并同意，因信息范围、采样水平、行业技术水平以及核验手段局限，泽诚数科无法确保核验结果的准确性。如您认为核验结果不准确，您可以重新发起验证或者您也可联系并告知泽诚数科前述情况的发生，以便泽诚数科重新进行验证并提升验证服务水平。
        </div>
        <div class="textarea">
          第十一条 用户信息的保护及授权
        </div>
        <div class="textarea">
          11.1
          【用户信息的保护】保护用户信息（即能够独立或与其他信息结合后识别用户身份的信息）是泽诚数科的一项原则，泽诚数科非常重视用户信息的保护，在您使用泽诚数字大宗加平台提供的服务时，您同意泽诚数字大宗加平台按照在泽诚数字大宗加平台公布的隐私保护政策收集、存储、使用、披露和保护您的用户信息。泽诚数科希望通过隐私保护政策向您清楚地介绍泽诚数科对您用户信息的处理方式，因此泽诚数科建议您完整地阅读《隐私保护政策》，以帮助您更好地保护您的隐私权。
        </div>
        <div class="textarea">
          11.2
          【用户信息的授权】为方便您使用泽诚数字大宗加平台其他相关服务，您授权泽诚数科将您在账号注册和使用泽诚数字大宗加平台服务过程中提供、形成的信息传递给泽诚数科等其他相关服务提供者，或从泽诚数科等其他相关服务提供者获取您在注册、使用相关服务期间提供、形成的信息。
        </div>
        <div class="textarea">
          第十二条 违约处理
        </div>
        <div class="textarea">
          12.1
          如果发现您违反本协议约定的，泽诚数科有权独立判断并视情节不经通知随时采取警告、拒绝使用泽诚数字大宗加平台服务、限制或禁止使用部分或全部功能、账号封禁直至注销的处罚，并公告处理结果，并有权根据实际情况决定是否恢复相关账号的使用。对涉嫌违法、犯罪的行为将保存相关记录，并依法向有关部门报告、配合调查。
        </div>
        <div class="textarea">
          12.2 您理解并同意，因您违反本协议规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；泽诚数科因此遭受损失的，您也应当一并赔偿。
        </div>
        <div class="textarea">
          第十三条 服务的变更、中断或终止
        </div>
        <div class="textarea">
          13.1
          【服务的中断】您理解，泽诚数科需要定期或不定期地对提供服务的系统或相关的设备进行检修或者维护，或对泽诚数字大宗加平台系统进行升级、改造。如因此类情况而造成服务在合理时间内的中断，泽诚数科无需为此承担任何责任。但泽诚数字大宗加平台应尽可能事先进行通告并根据情况在相应服务页面提前做出通知。
        </div>
        <div class="textarea">
          13.2 【服务中断、终止免责】如发生下列任何一种情形，泽诚数科有权不经通知而中断或终止向您提供本协议项下的服务而无需对您或任何第三方承担任何责任：
        </div>
        <div class="textarea">
          （1）根据法律规定您应提交真实信息，而您提供的资料不真实、或与注册时信息不一致又未能提供合理证明；
        </div>
        <div class="textarea">
          （2）您违反相关法律法规或本协议的约定；
        </div>
        <div class="textarea">
          （3）按照法律规定或主管部门的要求；
        </div>
        <div class="textarea">
          （4）出于安全的原因或其他必要的情形。
        </div>
        <div class="textarea">
          第十四条 免责条款
        </div>
        <div class="textarea">
          【审慎阅读】您理解并同意，泽诚数科在以下情况下不承担责任（包括但不限于）：
        </div>

        <div class="textarea">
          14.1 泽诚数科不对平台服务提供任何形式的保证，包括但不限于平台服务符合您的需求，平台服务不受干扰、及时提供或免于出错。
        </div>
        <div class="textarea">
          14.2 泽诚数字大宗加平台的合作单位所提供的服务内容及品质由该合作单位自行负责。
        </div>
        <div class="textarea">
          14.3
          泽诚数字大宗加平台不保证为用户提供便利而设置的外部链接的准确性和完整性，同时对于该等外部链接指向的不由泽诚数科实际控制的任何网页上的内容，泽诚数科不承担任何责任。另，对于泽诚数字平台提供的外部链接是为用户提供便利而设置，相当于是平台服务的一部分，对于链接的合法性是应保证的，但是不对链接所载信息的内容不作出任何判断。
        </div>
        <div class="textarea">
          14.4 对于本协议第十二条造成的服务变更、中断或终止，泽诚数字大宗加平台不承担责任。
        </div>
        <div class="textarea">
          14.5 因以下情况没有正确执行您提交的泽诚数字大宗加平台用户操作指令而造成损失的，泽诚数字大宗加平台不承担任何责任：
        </div>
        <div class="textarea">
          a）指令信息不明、存在乱码或不完整等；
        </div>
        <div class="textarea">
          b）您所拥有的产品或服务发生失效、终止等情况；
        </div>
        <div class="textarea">
          c）其他泽诚数科无过失的情况。
        </div>
        <div class="textarea">
          14.6 对于因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因造成的损失平台不承担任何责任。
        </div>
        <div class="textarea">
          14.7 您须安全使用账号，妥善保管、使用您的用户名、密码，对您保管不善造成的损失泽诚数字大宗加平台不承担责任。
        </div>
        <div class="textarea">
          14.8 如果您在共享环境下或在电脑被远程监控的情况下登录泽诚数字大宗加平台账号，由此造成的损失泽诚数科不承担责任。
        </div>
        <div class="textarea">
          14.9 泽诚数字大宗加平台不允许您使用他人身份信息注册，否则，因此产生的法律责任将由您本人承担，给他人造成损失的，您应给予赔偿。
        </div>
        <div class="textarea">
          14.10
          如您使用他人账号，须事先获得他人同意。一旦输入正确的用户名和密码，泽诚数科即视您为已获得他人同意。您与他人之间因此产生的任何纠纷与泽诚数科无关，对您或他人的损失泽诚数科不承担责任，并且如造成泽诚数科损失，泽诚数科将保留追索的权利。
        </div>
        <div class="textarea">
          14.11 如果泽诚数字大宗加平台用户将账号详细信息输入其他任何服务系统而不是输入泽诚数字大宗加平台相关网页安全登录系统，由此造成的损失泽诚数科不承担责任。
        </div>
        <div class="textarea">
          14.12 泽诚数字大宗加平台用户通过移动通讯设备登录相关网站和使用相关服务，因通讯过程中导致信息的不完整、丢失或被第三方拦截等不可归责于泽诚数字大宗加平台的情形，泽诚数科不承担责任。
        </div>
        <div class="textarea">
          14.13 因无法预见、无法避免且无法克服的不可抗力事件，导致泽诚数字大宗加平台在本协议项下的义务无法履行或无法按时履行，泽诚数科在不可抗力事件影响的范围内免责。
        </div>
        <div class="textarea">
          14.14 泽诚数字大宗加平台的用户信息是由用户自行提供的，泽诚数字大宗加平台无法保证该信息之准确、及时和完整。
        </div>
        <div class="textarea">
          14.15 如果数泽诚数字大宗加平台存在投入使用时的科学技术水平尚不能发现的缺陷，由此造成的损失泽诚数科不承担责任。
        </div>
        <div class="textarea">
          第十五条 知识产权
        </div>
        <div class="textarea">
          15.1
          【知识产权归属】除非泽诚数科另行书面声明，泽诚数字大宗加平台向您展示的和/或泽诚数字大宗加平台向您提供服务中的所有产品、技术、软件、程序、数据、文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档及其他信息、内容、资料的所有权利，包括但不限于版权、商标权、专利权、商业秘密及其他所有相关的世界范围内的权利，均归泽诚数科所有。未经泽诚数科明确书面授权，任何人不得擅自使用，包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上传、下载泽诚数字大宗加平台内的任何内容。访问泽诚数字大宗加平台和/或使用泽诚数字大宗加平台服务并无法让您获得所访问和/或您所使用的泽诚数字大宗加平台服务中的信息、内容、资料的任何权利，除非您获得相关信息、内容、资料的所有者的许可或通过其他方式获得法律的许可，您不得使用泽诚数字大宗加平台向您展示的和/或泽诚数科向您提供服务内中的任何信息、内容、资料。
        </div>
        <div class="textarea">
          15.2
          【商标使用】泽诚数字大宗加平台或泽诚数科官网上展示的泽诚数科文字、设计、图形及其组成部分，以及泽诚数字大宗加平台上展示的其他标识、徵记、产品和服务名称，均属于泽诚数科在中国（包括港澳台地区）和/或其它国家/地区的商标。未经泽诚数科明确书面授权，任何人不得以任何方式在任何国家或区域展示、使用、申请或注册、或以任何其他方式进行处理，也不得在任何国家或区域向他人表明您有权展示、使用、申请或注册、或以任何其他方式进行处理。本协议并未授予您使用、申请或注册泽诚数字大宗加平台上展示的或泽诚数字大宗加平台服务中所用的任何商标的权利。
        </div>
        <div class="textarea">
          15.3
          【侵权处理】泽诚数科非常尊重知识产权。如果您认为泽诚数字大宗加平台向您展示的和/或泽诚数科向您提供服务内中的任何信息、内容、资料可能涉嫌侵犯相关合法权益，可以与泽诚数科联系，泽诚数科收到后将及时处理。知识产权权利人认为其知识产权受到侵害的，可发邮件到泽诚数科提交投诉资料，请务必提交真实有效、完整清晰的材料，否则投诉将无法受理。投诉材料包括：a.权属证明；b.举报人的身份证明，身份证明可以是身份证或护照、营业执照等；如果举报人非权利人，请举报人提供代表权利人进行举报的书面授权证明。c.为确保投诉材料的真实性，在侵权举报中，您还需要签署以下法律声明：“我为所举报内容的合法权利人；我举报的内容侵犯了本人/公司相应的合法权益；如果本侵权举报内容不完全属实，我将承担由此产生的一切法律责任，并承担和赔偿泽诚数科因根据投诉人的通知书对相关帐号的处理而造成的任何损失。”
          泽诚数科有权依据知识产权权利人提供的侵权初步证据，对用户采取删除、屏蔽、断开链接、终止交易和服务等必要措施。
        </div>
        <div class="textarea">
          第十六条 协议更新与终止
        </div>
        <div class="textarea">
          16.1 协议的更新
        </div>
        <div class="textarea">
          16.1.1
          【审慎阅读】根据国家法律法规变化及网站运营需要，泽诚数科有权不定时变更或修改本协议或任何适用于某项服务的补充协议，如：为反映法律的变更或服务变化而进行的修改。当泽诚数科对本协议或任何适用于某项服务的补充协议做出变更或修改的，泽诚数科将通过泽诚数字大宗加平台或在适用的服务中予以公布。您应当定期查阅本协议条款。如果您在本协议内容公告变更/修改生效后继续使用泽诚数字大宗加平台服务的，表示您已充分阅读、理解并接受变更或修改后的协议内容；如果您不同意变更或修改后的协议内容，您可以拒绝使用泽诚数字大宗加平台服务。
        </div>

        <div class="textarea">
          16.1.2 本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
        </div>
        <div class="textarea">
          16.1.3 您与泽诚数科基于交易合作签署的其他协议与本协议不一致的，以双方签署的其他协议为准。
        </div>
        <div class="textarea">
          16.2 存在如下情形，泽诚数科有权终止本协议：
        </div>
        <div class="textarea">
          （1）您申请注销账号，泽诚数科确认的。
        </div>
        <div class="textarea">
          （2）您违反本协议累计达到3次的；
        </div>
        <div class="textarea">
          （3）其他法律法规规定及泽诚数科认为需要终止的情形。
        </div>
        <div class="textarea">
          协议终止后，泽诚数科有权继续保存您的注册信息及在泽诚数字大宗加平台上的所有交易信息，并有权视情况删除尚未交易的物品，及未实际履行交易信息。
        </div>
        <div class="textarea">
          第十七条 法律适用和争议管辖
        </div>
        <div class="textarea">
          17.1 【法律适用】本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如果法律无相关规定的，参照商业惯例和/或行业惯例。
        </div>
        <div class="textarea">
          17.2 【管辖条款】您因使用服务所产生和/或与服务有关的争议，由泽诚数科与您协商解决；协商不成时，任何一方均应向
        </div>
        <div class="textarea">
          陕西泽诚数字科技有限公司所在地有管辖权的人民法院提起诉讼。
        </div>
        <div class="textarea">
          17.3 除非另有单独协议约定，您通过泽诚数字大宗加平台享受相应服务过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：
        </div>
        <div class="textarea">
          （一）与争议相对方自主协商；
        </div>
        <div class="textarea">
          （二）使用泽诚数科提供的争议调处服务；在此情况下，您理解并同意，泽诚数科提供争议调处服务人员并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，除存在故意外，调处方对争议调处决定免责。
        </div>
        <div class="textarea">
          （三）请求其他依法成立的调解组织调解；
        </div>
        <div class="textarea">
          （四）向有关行政部门投诉；
        </div>
        <div class="textarea">
          （五）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；
        </div>
        <div class="textarea">
          （六）向人民法院提起诉讼。
        </div>
        <div class="textarea">
          第十八条 其他约定
        </div>
        <div class="textarea">
          18.1 未经泽诚数科事先同意，您不得随意转让本协议项下属于您的权利和义务。
        </div>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showyinsi = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showyinsi: false,
      showxieyi: false,
    };
  },
  methods: {
    show(type) {
      if (type == "xieyi") {
        this.showxieyi = true;
      } else {
        this.showyinsi = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .el-dialog__footer {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  .el-button--primary,
  .el-button--primary.is-plain {
    background-color: #337CFB;
    border-color: transparent;
    color: #fff !important;
  }

  .el-button--primary:hover,
  .el-button--primary:focus,
  .el-button--primary.is-plain:hover,
  .el-button--primary.is-plain:focus {
    background-color: rgba(250, 138, 0, 0.9) !important;
    border-color: transparent;
  }

  .el-dialog__body {
    padding: 20px 38px;
    height: 450px;
    overflow-y: auto;

    p {
      margin: 0 0 15px;
    }

    .title {
      font-size: 20px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      margin: 15px 0;
    }

    .textarea {
      line-height: 2;
    }
  }
}
</style>
