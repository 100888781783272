import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main',
        component: Main,
        redirect: '/home'
    },{
        path: '/refresh',
        name: "refresh",
        component: () => import('../views/Home/refresh')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import ('../views/Home')
    },
    {
        path: '/caigou',
        name: 'caigou',
        component: () => import ('../views/Caigou')
    },
    {
        path: '/xiangqing',
        name: 'xiangqing',
        component: () => import ('../views/Caigou/CaigouXiangqing')
    }, {
        path: '/shenqing',
        name: 'shenqing',
        component: () => import ('../views/Caigou/Caigoushenqing')
    }, {
        path: '/fangan',
        name: 'fangan',
        component: () => import ('../views/Fangan')
    }, {
        path: '/zixun',
        name: 'zixun',
        component: () => import ('../views/Zixun')
    }, {
        path: '/zixunxiangqing',
        name: 'zixunxiangqing',
        component: () => import ('../views/Zixun/ZixunXiangqing')
    }, {
        path: '/jieguo',
        name: 'jieguo',
        component: () => import ('../views/Caigou/ShengqingJg')
    }, {
        path: '/wuliu',
        name: 'wuliu',
        component: () => import ('../views/Wuliu')
    }, {
        path: '/cuohe',
        name: 'cuohe',
        component: () => import ('../views/Cuohe')
    }, {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import ('../views/AboutUs')
    },
    {
        path: '/addMaoYi',
        name: 'addMaoYi',
        component: () => import ('../views/MaoYi')
    },
    {
        path: '/maoyiSuccess',
        name: 'maoyiSuccess',
        component: () => import ('../views/MaoYi/maoyiSuccess')
    },
    {
        path: '/bank',
        name: 'bank',
        component: () => import ('../views/Bank')
    }
]


const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() { // x代表横坐标，y代表纵坐标
        return {x: 0, y: 0};
    }
})

export default router
