<template>
  <el-dialog
    title=""
    :visible.sync="isshowLogin"
    width="530px"
    :show-close="true"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
    >
      <div class="tabs">
        <div class="yzm text-orange">登录</div>
      </div>
      <!-- <transition name="el-zoom-in-left"> -->
      <div>
        <el-form-item prop="userphone">
          <el-input
            v-model="loginForm.userphone"
            type="text"
            auto-complete="off"
            placeholder="请输入手机号码"
            maxlength="11"
          >
            <i slot="prefix" class="el-input__icon iconfont icon-shouji"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="code" class="yzmbox">
          <el-input
            v-model="loginForm.code"
            placeholder="验证码"
            @keyup.enter.native="handleLogin"
            maxlength="5"
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-yanzhengma"
            ></i>
          </el-input>
          <div class="login-code">
            <img :src="codeUrl" @click="getCode" class="login-code-img" />
          </div>
        </el-form-item>
        <el-form-item prop="phonecode" class="yzmbox">
          <el-input
            v-model="loginForm.phonecode"
            auto-complete="off"
            placeholder="请输入短信验证码"
            @keyup.enter.native="handleLogin"
            type="text"
            maxlength="4"
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-yanzhengma"
            ></i>
          </el-input>
          <el-button
            :disabled="disabled"
            @click="getphoneCode"
            style="width: 180px"
            type="primary"
            >{{ valiBtn }}</el-button
          >
        </el-form-item>
      </div>
      <div class="login-xieyi" :class="animation == 1 ? 'animation' : ''">
        <el-checkbox v-model="loginForm.rememberMe" style="margin: 0 10px 0 0"
          >我已阅读并同意
          <span class="text-orange" @click="opendia('xieyi')"
            >《泽诚数字大宗加用户服务协议》</span
          >
          <span class="text-orange" @click="opendia('yinsi')"
            >《隐私保护政策》</span
          >
        </el-checkbox>
      </div>

      <el-form-item style="width: 100%">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width: 100%"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
        <div style="float: right" v-if="register">
          <router-link class="link-type" :to="'/register'"
            >立即注册</router-link
          >
        </div>
        <div class="login-tips">未注册过的手机将自动创建为平台账号</div>
      </el-form-item>
    </el-form>
    <!--  底部  -->
    <div class="el-login-footer">
      <span
        >Copyright © 2023 泽诚数科 All Rights Reserved. 陕ICP备19007461号-1
        陕西泽诚数字科技有限公司</span
      >
    </div>
    <dialogs ref="dialogxieyi"></dialogs>
  </el-dialog>
</template>

<script>
import { sendCode, verifyCode, phoneLogin, getCodeImg } from "@/api/login";
import { setToken, setExpiresIn } from "@/utils/auth";

import dialogs from "./loginXieyiDialog.vue";
export default {
  name: "Login",
  data() {
    var checkPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (!/^[1][3-9][\d]{9}/.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    var checkCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (!/^[0-9]+$/.test(value) || !/^\d{4}$/.test(value)) {
        callback(new Error("请输入4位数字的验证码"));
      } else {
        callback();
      }
    };
    return {
      animation: null,
      codeUrl: "",
      isshowLogin: false,
      loginForm: {
        rememberMe: false,
        code: "",
        uuid: "",
        userphone: "",
        phonecode: "",
      },
      loginType: "zhanghao",
      loginRules: {
        phonecode: [
          {
            validator: checkCode,
            required: true,
            trigger: "blur",
          },
        ],
        userphone: [
          {
            validator: checkPhone,
            required: true,
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            trigger: "change",
            message: "请输入验证码",
          },
        ],
      },

      valiBtn: "获取验证码",
      disabled: false,
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined,
      regcode: "",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  components: {
    dialogs,
  },
  created() {
    this.getCode();
  },
  methods: {
    showLogin() {
      this.isshowLogin = true;
    },
    //获取验证码 并只验证手机号 是否正确
    getphoneCode() {
      this.$refs["loginForm"].validateField("userphone", (err) => {
        if (err) {
          return;
        } else {
          let query = {
            phone: this.loginForm.userphone,
            type: 37,
          };
          sendCode(query).then((res) => {
            this.regcode = res.data.data;
          });
          this.tackBtn(); //验证码倒数60秒
        }
      });
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },

    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.loginForm.rememberMe == false) {
            this.animation = 1;
          } else {
            this.animation = null;
            this.loading = true;

            let query = {
              code: this.loginForm.phonecode,
              v: this.regcode,
            };
            verifyCode(query)
              .then((r) => {
                if (r.data.code == 200) {
                  console.log("验证码==", r);
                  this.phonenumLogin();
                  this.loading = false;
                } else {
                }
              })
              .catch((Error) => {
                this.loading = false;
                if (this.captchaEnabled) {
                  this.getCode();
                }
              });
          }
        }
      });
    },
    getCode() {
      getCodeImg().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.data.img;
        this.loginForm.uuid = res.data.uuid;
      });
    },
    phonenumLogin() {
      let data = {
        phone: this.loginForm.userphone,
        code: this.loginForm.code,
        uuid: this.loginForm.uuid,
        loginType: 2,
      };
      phoneLogin(data).then((res) => {
        if (res.data.code == 200) {
          console.log("手机号登录==", res.data);
          setToken(res.data.data.access_token);
          setExpiresIn(res.data.data.expires_in);
          sessionStorage.setItem("phonenumber", this.loginForm.userphone);
          sessionStorage.setItem("token", res.data.data.access_token);
          // sessionStorage.setItem("phonenumber", res.data.data.phonenumber);

          this.$emit("getInfor");
          this.isshowLogin = false;
          if (res.data.data.lastLoginCompany) {
            sessionStorage.setItem(
              "lastLoginCompany",
              JSON.stringify(res.data.data.lastLoginCompany)
            );
          }
          setTimeout(() => {
            location.reload();
          }, 500);
        }
      });
    },
    opendia(type) {
      this.$refs.dialogxieyi.show(type);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-dialog__header {
    padding: 0;
  }

  .text-orange {
    color: #337CFB;
  }

  .yzm {
    font-size: 16px;
    line-height: 40px;
  }

  .el-button {
    border: 1px solid #337CFB;
    color: #337CFB;
    height: 38px !important;
    line-height: 38px !important;
    font-size: 16px !important;
    padding: 0 !important;
  }

  .el-button:hover,
  .el-button:focus {
    color: #ffffff;
    background: #337CFB;
    border: 1px solid #337CFB;
  }

  //按钮
  .el-button--primary,
  .el-button--primary.is-plain {
    background-color: #337CFB;
    border-color: transparent;
    color: #fff !important;
  }

  .el-button--primary:hover,
  .el-button--primary:focus,
  .el-button--primary.is-plain:hover,
  .el-button--primary.is-plain:focus {
    background-color: rgba(51, 124, 251, 0.9) !important;
    border-color: transparent;
  }

  .el-button--text {
    border-color: transparent !important;
  }

  .el-button--text:hover,
  .el-button--text:focus {
    color: rgba(51, 124, 251, 0.9) !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:hover,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:active {
    background: #f7f8fa !important;
    border: 2px solid #ececec !important;

    color: rgba(143, 143, 143, 0.4) !important;
  }

  .el-button.is-disabled,
  .el-button.is-disabled:hover,
  .el-button.is-disabled:focus {
    background: #f7f8fa !important;
    border: 2px solid #ececec !important;

    color: rgba(143, 143, 143, 0.4) !important;
  }

  .tabs {
    display: flex;
    height: 20px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #a6a6a6;
    margin-bottom: 22px;
    cursor: pointer;
  }

  .title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
    font-size: 26px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #21232d;
  }

  .login-form {
    border-radius: 6px;
    background: #ffffff;

    .el-input {
      height: 38px;

      input {
        height: 38px;
      }

      .el-input__inner {
        border-radius: 2px;
      }
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }

    .yzmbox {
      .el-input__inner {
        border-radius: 2px 0 0 2px;
        border-right: 0;
      }

      .el-button--medium {
        border-radius: 0;
        padding-top: 11.5px;
        padding-bottom: 9.5px;
      }

      .el-form-item__content {
        display: flex;

        .el-input {
          flex: 1;
        }
      }
    }
    .login-code-img {
      height: 38px;
    }
  }

  .login-tip {
    font-size: 12px;
    text-align: center;
    color: #bfbfbf;
  }

  .login-code {
    width: 101px;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .login-xieyi {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #909090;
    white-space: nowrap;
    margin: 20px 0;

    .el-checkbox {
      margin-bottom: 0;
    }

    .text-orange {
      cursor: pointer;
    }
  }

  .login-tips {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #909090;
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .login-code-img {
    height: 38px;
  }
}

.animation {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
