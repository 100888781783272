<template>
  <div class="header-tabs">
    <div class="icon">
      <span class="iconfont icon-ZCSZDZ"></span>
    </div>
    <el-menu class="el-menu-demo" background-color="rgba(32, 48, 64, 0)"       mode="horizontal" :router="true" text-color="#fff"
      :default-active="$route.path" active-text-color="#337CFB" @select="handleSelect">
      <el-menu-item v-for="(item, index) in navList" :key="index" :index="item.path" :route="{ path: item.path }">
        <div class="menuItem" @mouseenter="handleEnter(index)">
          {{ item.navName }}
        </div>
        <!-- {{ item.navName }} -->
      </el-menu-item>
    </el-menu>
    <div v-if="controlShow" class="hoverBox" @mouseleave="handleLeave">
      <div class="left">
        <div>贸易撮合</div>
        <span>
          贸易撮合基于平台沉淀稳定的贸易背景，提供撮合服务，赋能供应链，较市场资金成本降低N个百分点资金年化利率
        </span>
      </div>
      <div class="right">
        <div class="right_top">
          <div class="top_tit">
            <div></div>
            <span>贸易撮合</span>
          </div>
          <div class="top_center">
            <div class="center_tab">
              <div class="center_tab_left">
                提交贸易需求，贸易撮合匹配
              </div>
              <div class="center_tab_right">
                <div>
                  <span>♦</span><span>规模匹配</span><span>♦</span>
                </div>
                <div>
                  <span>♦</span><span>周期匹配</span><span>♦</span>
                </div>
                <div>
                  <span>♦</span><span>品类匹配</span><span>♦</span>
                </div>
                <div>
                  <span>♦</span><span>真实贸易</span><span>♦</span>
                </div>
              </div>
            </div>
            <div class="center_body">
              <div class="center_body_box">
                <span class="tit">品类多样</span>
                <img class="line" src="https://zechengsk.oss-cn-beijing.aliyuncs.com/www/image/maoyi/website/tab/tab_shuxian.png" alt="">
                <div class="line_right">
                  <div>
                    <span>能源</span>
                    <span>建材</span>
                  </div>
                  <div>
                    <span>化工</span>
                    <span>有色</span>
                  </div>
                </div>
                <img class="sanjiao_img" src="https://zechengsk.oss-cn-beijing.aliyuncs.com/www/image/maoyi/website/tab/tab_sanjiao.png" alt="">
              </div>
              <div class="center_body_box">
                <span class="tit">规模灵活</span>
                <img class="line" src="https://zechengsk.oss-cn-beijing.aliyuncs.com/www/image/maoyi/website/tab/tab_shuxian.png" alt="">
                <div class="line_right">
                  <div>
                    <span>支持单笔</span>
                  </div>
                  <div>
                    <span>支持周期</span>
                  </div>
                </div>
                <img class="sanjiao_img" src="https://zechengsk.oss-cn-beijing.aliyuncs.com/www/image/maoyi/website/tab/tab_sanjiao.png" alt="">
              </div>
              <div class="center_body_box">
                <span class="tit">参与灵活</span>
                <img class="line" src="https://zechengsk.oss-cn-beijing.aliyuncs.com/www/image/maoyi/website/tab/tab_shuxian.png" alt="">
                <div class="line_right">
                  <div>
                    <span>支付方式银/商票</span>
                  </div>
                  <div>
                    <span>支持单/多户参与</span>
                  </div>
                </div>
                <img class="sanjiao_img" src="https://zechengsk.oss-cn-beijing.aliyuncs.com/www/image/maoyi/website/tab/tab_sanjiao.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="right_bottom">
          <div class="bottom_tit">
            <div></div>
            <span>服务流程</span>
          </div>
          <div class="bottom_center">
            <img src="https://zechengsk.oss-cn-beijing.aliyuncs.com/www/image/maoyi/website/tab/tab_gress.png" alt="">
            <div class="bottom_center_txt">
              <span>入驻平台</span>
              <span>对接客服</span>
              <span>准入资料提交</span>
              <span>风险审核</span>
              <span>撮合匹配</span>
              <span>贸易项目建立</span>
              <span>数字化存证</span>
              <span>项目完结</span>
            </div>
            <div class="bottom_center_btn">
              <div @click="handleBtn(0)">查看详情</div>
              <div @click="handleBtn(1)">提交撮合需求</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="controlShow" class="shadow_bg"></div>
    <!-- craig--new -->
    <div v-if="!isLogin" class="loginBox" @click="showLogin">
      登录/注册
    </div>
    <div class="qiye-modei" v-if="isLogin">
      <p class="qiye qiye-kong" @click.stop="toShowMaoyi" v-if="joinList.length > 0">
        <span>撮合推荐</span><i class="el-icon-arrow-down"></i>
      </p>
      <p class="qiye" @click="toLink" v-if="isLogin">企业中心</p>
      <p class="qiye qiye1" @click="loginOut" v-if="isLogin">退出登录</p>
      <div v-show="showMaoyi" class="maoyi">
        <div class="box" v-for="(item, index) in joinList" :key="index" @click="toAdminMylList">
          <div class="row1 flexrow">
            <div class="col1">{{ item.mylName }}</div>
            <div class="col2">推荐时间：{{ item.createTime }}</div>
          </div>
          <div class="row2">
            <div class="col1 flexrow">
              <div class="cols flexrow">
                <div class="label">品类：</div>
                {{ item.typeName }}
              </div>
              <div class="cols flexrow">
                <div class="label">规格：</div>
                {{ item.goodType }}
              </div>
              <div class="cols flexrow">
                <div class="label">数量：</div>
                {{ item.goodNum }}吨
              </div>
            </div>
            <div class="col1 flexrow">
              <div class="cols flexrow">
                <div class="label">货源企业：</div>
                {{ item.hyCompanyName }}
              </div>
              <div class="cols"></div>
              <div class="cols flexrow">
                <div class="label">终端企业：</div>
                {{ item.zdCompanyName }}
              </div>
            </div>
          </div>
          <div class="row3 flexrow">
            <div class="cols1">
              <div class="label">上游企业信息</div>
              <div v-if="item.beforeCompanyList.length>0">
                <div v-for="(list,index) in item.beforeCompanyList" :key="index"  class="companyList">
                 {{ list.companyName }}
                </div>
              </div>
              <div v-else class="companyList">
                {{ item.hyCompanyName }}
              </div>
            </div>
            <div class="cols2" :class="item.curCompany.status == '1' ? 'cols2' : 'cols-ing'"
              @click.stop="setInvited(item)">
              <div class="btn-org flexrow">
                <div class="left" v-if="item.curCompany.status !== '1'"></div>
                {{ item.curCompany.status == "1" ? "参与贸易" : "贸易进行中" }}

                <div class="right">
                  <i class="el-icon-arrow-right" v-if="item.curCompany.status == '1'"></i>
                </div>
              </div>
            </div>
            <div class="cols1">
              <div class="label">下游企业信息</div>
              <div v-if="item.afterCompanyList.length>0">
                <div v-for="(list,index) in item.afterCompanyList" :key="index" class="companyList">
                {{ list.companyName }}
                </div>
              </div>
              <div v-else class="companyList">
                {{ item.zdCompanyName }}
              </div>
            </div>
          </div>
        </div>
        <div class="tips">
          <i class="el-icon-info"></i>贸易背景企业同时参与后，即可与上下游企业发起合同、货权、结算、发票、资金5流单证流转与审核
        </div>
        <div class="ismore" v-if="isMore" @click="toAdminMylList">
          <div>最多展示2条数据，点击查看更多</div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <Login ref="loginDia" @getInfor="getInfors"></Login>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { getJoinList, invited, logout, getSupplyShow } from "@/api/home";
import Login from "@/components/login.vue";
export default {
  name: "headerTabs",
  components:{
    Login,
  },
  data() {
    return {
      showMaoyi: false,
      isLogin: false,
      isMore: false,
      activeIndex: "/home",
      navList: [
        { path: "/home", navName: "首页" },
        // { path: "/caigou", navName: "阳光采购" },
        { path: "/cuohe", navName: "贸易撮合" },
        { path: "/wuliu", navName: "仓储物流服务" },
        { path: "/bank", navName: "供应链金融" },
        // { path: "/fangan", navName: "解决方案" },
        // { path: "/zixun", navName: "资讯中心" },
        { path: "/aboutus", navName: "关于我们" },
      ],
      joinList: [],
      hasCompany: false,
      //craig-新增
      phonenumber:'',
      applyCompanyName:'',
      showDenglu:'',
      showCompany:'',
      controlShow:false,
    };
  },
  mounted() {
    this.activeIndex = sessionStorage.getItem("keyPath") || "/home";
    document.addEventListener("click", this.bodyCloseMenus);
    if (
      sessionStorage.getItem("token") !== null &&
      sessionStorage.getItem("token") != ""
    ) {
      this.getJoinlists();
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    this.getSupply();
    this.hasCompany =
      sessionStorage.getItem("lastLoginCompany") &&
      sessionStorage.getItem("lastLoginCompany") != undefined &&
      sessionStorage.getItem("lastLoginCompany") != null;
    console.log("this.hasCompany", this.hasCompany);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
  watch: {
    activeIndex(newValue) {
      if (newValue != this.$route.path) {
        this.activeIndex = this.$route.path;
      }
    },
  },
  methods: {
    //是否显示阳光采购模块
    getSupply() {
      getSupplyShow().then((res) => {
        console.log("阳光采购是否展示", JSON.parse(res.data.msg));
        let isShow = JSON.parse(res.data.msg);
        if (
          sessionStorage.getItem("lastLoginCompany") &&
          sessionStorage.getItem("showDenglu") == "yidenglu" &&
          isShow
        ) {
          this.navList = [
            { path: "/home", navName: "首页" },
            { path: "/caigou", navName: "阳光采购" },
            { path: "/wuliu", navName: "物流仓储服务" },
            { path: "/fangan", navName: "解决方案" },
            { path: "/zixun", navName: "资讯中心" },
          ];
        } else {
          this.navList = [
            { path: "/home", navName: "首页" },
            { path: "/cuohe", navName: "贸易撮合" },
            { path: "/wuliu", navName: "仓储物流服务" },
            { path: "/bank", navName: "供应链金融" },
            // { path: "/fangan", navName: "解决方案" },
            // { path: "/zixun", navName: "资讯中心" },
            { path: "/aboutus", navName: "关于我们" },
          ];
        }
      });
    },
    toLink() {
      const cc = window.location.origin;
      if (cc.indexOf("43.140.218.34") !== -1) {
        window.open("http://43.140.218.34:9000/accountManage/index?token=" + getToken());
      } else {
        window.open("https://admin.zcdazong.com/accountManage/index?token=" + getToken());
      }
    },
    loginOut() {
      let that = this;
      that
        .$confirm("确定退出系统吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          logout().then((res) => { });
          that.resetData();
        })
        .catch(() => { });
    },
    resetData() {
      console.log("退出");
      this.clearCookie();
      sessionStorage.clear();
      window.localStorage.clear();
      location.href = "/refresh";
    },
    // 获取加入的贸易背景列表
    getJoinlists() {
      getJoinList().then((res) => {
        if (res.data.code == 200) {
          let arr = res.data.data;
          // let arr1 = arr.filter((val) => val.curCompany.status == "1");
          let arr1 = [...res.data.data];
          this.joinList = arr1.length > 2 ? arr1.splice(0, 2) : arr1;
          this.isMore = res.data.data.length > 2 ? true : false;
        }
      });
    },
    setInvited(row) {
      if (row.curCompany.status == 1) {
        invited({ mylCompanyId: row.curCompany.mylCompanyId }).then((res) => {
          this.$message({
            message: "接受成功",
            type: "success",
          });
          this.getJoinlists();
        });
      } else {
        this.toAdminMylList();
      }
    },
    handleSelect(keyPath) {
      sessionStorage.setItem("keyPath", keyPath);
      this.controlShow = false;
    },

    toShowMaoyi() {
      this.showMaoyi = this.showMaoyi ? false : true;
    },
    bodyCloseMenus(e) {
      let self = this;
      if (self.showMaoyi == true) {
        self.showMaoyi = false;
      }
    },
    toAdminMylList() {
      const cc = window.location.origin;
      if (cc.indexOf("43.140.218.34") !== -1) {
        window.open(
          "http://43.140.218.34:9000/maoyi/maoyiProject?token=" +
          getToken()
        );
      } else {
        window.open(
          "https://admin.zcdazong.com/maoyi/maoyiProject?token=" +
          getToken()
        );
      }
    },
    //清空cookie
    clearCookie() {
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
        for (var i = keys.length; i--;) {
          document.cookie =
            keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); //清除当前域名下的,例如：m.zcdazong.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=" +
            document.domain +
            ";expires=" +
            new Date(0).toUTCString(); //清除当前域名下的，例如 .m.zcdazong.com
          document.cookie =
            keys[i] +
            "=0;path=/;domain=zcdazong.com;expires=" +
            new Date(0).toUTCString(); //清除一级域名下的或指定的，例如 .zcdazong.com
        }
      }
      console.log("已清除cookie");
    },
    // 打开登录弹窗
    showLogin() {
      this.$refs.loginDia.showLogin();
    },
    // 登录后回调
    getInfors() {
      console.log("调取父组件方法");
      getInfor().then((res) => {
        if (res.data.code == 200) {
          sessionStorage.setItem("showDenglu", "yidenglu");
          console.log("res.data.导航getInfor", res.data);
          // sessionStorage.setItem("phonenumber", res.data.user.phonenumber);
          this.phonenumber =
            res.data.user.phonenumber || sessionStorage.getItem("phonenumber");
          if (res.data.companyVO.status == 1) {
          } else if (!res.data.companyVO.status) {
          } else if (res.data.companyVO.status == 2) {
          } else {
            let lastLoginCompany = JSON.parse(
              sessionStorage.getItem("lastLoginCompany")
            );
            this.applyCompanyName = lastLoginCompany.companyName;
          }
        }
      });
      this.showGongsi();
    },
    showGongsi() {
      this.showDenglu = sessionStorage.getItem("showDenglu");
      if (sessionStorage.getItem("lastLoginCompany")) {
        this.showCompany = true;
        let lastLoginCompany = JSON.parse(
          sessionStorage.getItem("lastLoginCompany")
        );
        this.applyCompanyName = lastLoginCompany.companyName;
      } else {
        this.phonenumber = JSON.parse(sessionStorage.getItem("phonenumber"));
        this.showCompany = false;
      }
    },
    // 鼠标滑入 贸易撮合 menu
    handleEnter(e){
      if(e == 1){
        this.controlShow = true;
      }else{
        this.controlShow = false;
      }
    },
    // 鼠标离开整个menu菜单
    handleLeave(){
      this.controlShow = false;
    },
    // 点击hoverBox中的按钮
    handleBtn(e){
      if(e){
        this.$router.push({
          path:'/addMaoYi',
        })
      }else{
        this.$router.push({
          path:'/cuohe',
        })
      }
      this.controlShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep{
  .el-menu-item:hover{
    background-color: transparent !important;
    color: #337CFB !important;
  }
}
.header-tabs {
  position: fixed;
  top: 0;
  z-index: 999;
  // width: 1780px;
  width: 100%;
  height: 68px;
  background: rgba(32, 48, 64, 0.60);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 250px;
  .shadow_bg{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: rgba(32, 48, 64, 0.60);
    z-index: -1;
  }
  .hoverBox{
    width: 100%;
    height: 616px;
    position: absolute;
    top: 68px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .left{
      width: 29%;
      height: 616px;
      background: url('https://zechengsk.oss-cn-beijing.aliyuncs.com/www/image/maoyi/website/tab/tab_left.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 138px 0 0 110px;
      div{
        font-size: 30px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 42px;
        letter-spacing: 4px;
        margin-bottom: 50px;
      }
      span{
        width: 339px;
        font-size: 20px;
        line-height: 40px;
        color: #FFFFFF;
        text-align: justify;
        font-weight: 400;
      }
    }
    .right{
      width: calc(100% - 29%);
      height: 616px;
      background-color: #FFFFFF;
      .right_top{
        width: 100%;
        .top_tit{
          width: 100%;
          height: 68px;
          background: rgba(51,124,251,0.1);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          padding-left: 40px;
          div{
            width: 8px;
            height: 33px;
            background-color: #337CFB;
            margin-right: 10px;
          }
          span{
            font-size: 24px;
            letter-spacing: 2px;
          }
        }
        .top_center{
          width: 90%;
          height: 230px;
          background: rgba(51,124,251,0.02);
          border-radius: 8px;
          margin: 20px auto 0;
          .center_tab{
            width: 100%;
            height: 50px;
            background: rgba(51,124,251,0.06);
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            padding-left: 20px;
            .center_tab_left{
              font-size: 20px;
              font-weight: 600;
              color: #203040;
              line-height: 28px;
              margin-right: 70px;
            }
            .center_tab_right{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div{
                margin-right: 40px;
                color: #337CFB;
                span:nth-child(2){
                  margin: 0 3px;
                }
              }
            }
          }
          .center_body{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .center_body_box{
              width: 290px;
              height: 140px;
              box-shadow: 0px 20px 15px -18px rgba(161,182,218,0.5);
              border-radius: 4px;
              border: 1px solid rgba(32,48,64,0.1);
              display: flex;
              justify-content: flex-start;
              align-items: center;
              position: relative;
              margin-top: 20px;
              box-sizing: border-box;
              padding-left: 50px;
              .tit{
                writing-mode: vertical-rl;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 5px;
              }
              .line{
                width: 4px;
                height: auto;
                margin: 0 30px;
              }
              .line_right{
                div:nth-child(1){
                  margin-bottom: 30px;
                }
                div{
                  span:nth-child(1){
                    margin-right: 30px;
                  }
                }
              }
              .sanjiao_img{
                width: 50px;
                height: auto;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-25px,-50%);
              }
            }
            .center_body_box:nth-child(2){
              margin: 20px 100px 0;
            }
          }
        }
      }
      .right_bottom{
        margin-top: 20px;
        .bottom_tit{
          width: 100%;
          height: 68px;
          background: rgba(51,124,251,0.1);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          padding-left: 40px;
          div{
            width: 8px;
            height: 33px;
            background-color: #337CFB;
            margin-right: 10px;
          }
          span{
            font-size: 24px;
            letter-spacing: 2px;
          }
        }
        .bottom_center{
          width: 1280px;
          img{
            display: block;
            width: 100%;
            height: auto;
            margin: 20px auto 5px;
          }
          .bottom_center_txt{
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            span{
              width: 100px;
              text-align: center;
            }
          }
          .bottom_center_btn{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            div{
              width: 150px;
              height: 48px;
              border-radius: 4px;
              border: 1px solid #337CFB;
              color: #337CFB;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 10px;
              cursor: pointer;
            }
            div:nth-child(2){
              width: 170px;
              background: #337CFB;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }

  .flexrow {
    display: flex;
  }

  // padding: 0 70px;
  .icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconfont {
      font-size: 30px;
      color: #ffffff;
    }
  }

  .el-menu-demo {
    width: 60%;
    flex: 1;
    background-color: rgba(32, 48, 64, 0.60);
    height: 100%;
    border: none !important;
    display: flex;
    justify-content: center;
    flex: 1;

    .el-menu-item {
      height: 100%;
      line-height: 68px;
      font-size: 20px;
      font-family: SourceHanSansCN-Normal !important;
      margin: 0 20px;
      .menuItem{
        width: 100%;
      }
      span{
        display: none;
      }
    }
    .el-menu-item :hover{
      // background-color: pink !important;
      background: transparent !important;
    }
    .el-menu-item :hover span{
      display: block;
    }

    .is-active {
      height: 100%;
      line-height: 68px;
      font-size: 20px;
      font-weight: bold;
      // border-bottom: 3px solid #337CFB !important;
      border-bottom: none;
      font-family: SourceHanSansCN-Normal !important;
    }
  }

  .loginBox{
    width: 132px;
    height: 47px;
    background-color: #337CFB;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .qiye-modei {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    position: relative;
    padding-right: 50px;

    .qiye {
      min-width: 104px;
      height: 35px;
      text-align: center;
      line-height: 37px;
      font-size: 14px;
      color: #ffffff;
      background-color: #337CFB;
      border-radius: 2px;
      border: 1px solid #337CFB;
      cursor: pointer;
    }

    .qiye1 {
      background: none;
      color: #337CFB;
      margin-left: 20px;
    }

    .qiye-kong {
      margin-right: 20px;
      background: none;
      color: #337CFB;
    }

    .el-icon-arrow-down {
      margin-left: 10px;
    }
  }

  .maoyi {
    position: absolute;
    right: 180px;
    top: 78px;
    width: 543px;
    padding: 27px 20px 24px;
    background-image: url("https://zechengsk.oss-cn-beijing.aliyuncs.com/www/image/maoyi/website/common/maoyiSelect_bg.png");
    background-size: 100% 100%;

    .tips,
    .ismore {
      display: flex;
      font-size: 12px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #337CFB;
      align-items: baseline;
      justify-content: center;
      margin-top: 16px;
    }

    .ismore {
      cursor: pointer;
      font-size: 14px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #909090;
    }

    .box {
      background: #ffffff;
      border-radius: 4px;
      padding: 12px;
      cursor: pointer;

      .row1 {
        align-items: center;
        justify-content: space-between;

        .col1 {
          font-size: 16px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #337CFB;
          border-bottom: 1px solid #337CFB;
        }

        .col2 {
          font-size: 12px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #909090;
        }
      }

      .row2 {
        margin-top: 12px;
        padding: 12px;
        background: #fffefb;
        border-radius: 4px;
        border: 1px solid rgba(51, 124, 251, 0.3);

        .col1 {
          align-items: center;
          font-size: 14px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #282828;

          &:first-child {
            margin-bottom: 12px;
          }

          .cols {
            width: 30%;

            .label {
              color: #6a6a6a;
            }
          }
        }
      }

      .row3 {
        margin-top: 12px;
        justify-content: space-between;

        .cols1 {
          width: 180px;
          padding: 8px;
          background: #fffefb;
          border-radius: 4px;
          opacity: 1;
          border: 1px solid rgba(51, 124, 251, 0.5);
          font-size: 12px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          color: #6a6a6a;

          .label {
            color: #282828;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .companyList{
            font-family: AlibabaPuHuiTiM;
            font-size: 12px;
            color: #6A6A6A;
            margin-bottom: 10px;
            &::before{
              content: '';
              position: relative;
              top: -2px;
              display: inline-block;
              width: 5px;
              height: 5px;
              background-color: #6A6A6A;
              border-radius: 50%;
            }
          }
        }

        .cols2 {
          width: 107px;
          background: #337CFB;
          border-radius: 4px;
          font-size: 14px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;

          .btn-org {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Source Han Sans SC-Bold, Source Han Sans SC;
            font-weight: bold;
            color: #ffffff;
          }

          .left {
            width: 6px;
            height: 6px;
            background: #07c160;
            border-radius: 50%;
            margin-right: 4px;
          }

          .right {
            width: 12px;
            height: 12px;
            background: #ffffff;
            border-radius: 50%;
            position: relative;
            margin-left: 4px;

            .el-icon-arrow-right {
              position: absolute;
              top: 0;
              left: 1px;
              font-size: 12px;
              color: #337CFB;
              font-weight: bold;
            }
          }
        }

        .cols-ing {
          width: 107px;
          background: #fffefb;
          border-radius: 4px;
          border: 1px solid rgba(51, 124, 251, 0.5);

          .btn-org {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Source Han Sans SC-Bold, Source Han Sans SC;
            font-weight: bold;

            color: #07c160;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .header-tabs {
    padding: 0 150px;
    .hoverBox{
      width: 100%;
      height: 580px;
      .left{
        height: 580px;
        padding: 138px 0 0 40px;
        div{
          font-size: 28px;
        }
        span{
          font-size: 18px;
        }
      }
      .right{
        height: 580px;
        .right_top{
          .top_tit{
            height: 58px;
            div{
              width: 6px;
              height: 30px;
            }
            span{
              font-size: 22px;
            }
          }
          .top_center{
            width: 95%;
            .center_tab{
              .center_tab_left{
                margin-right: 40px;
              }
              .center_tab_right{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                div{
                  margin-right: 40px;
                  color: #337CFB;
                  span:nth-child(2){
                    margin: 0 3px;
                  }
                }
              }
            }
            .center_body{
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              .center_body_box{
                width: 260px;
                .line{
                  margin: 0 20px;
                }
              }
              .center_body_box:nth-child(2){
                margin: 20px 40px 0;
              }
            }
          }
        }
        .right_bottom{
          margin-top: 10px;
          .bottom_tit{
            width: 100%;
            height: 58px;
            div{
              width: 6px;
              height: 30px;
            }
            span{
              font-size: 22px;
              letter-spacing: 2px;
            }
          }
          .bottom_center{
            width: 100%;
          }
        }
      }
    }
    .el-menu-demo {
      width: 60%;
      .el-menu-item {
        font-size: 16px;
      }
      .is-active {
        font-size: 16px;
      }
    }
  }
}
</style>
