<template>
	<div class="main"></div>
</template>

<script>
	export default{
		name:"main",
		data(){
			return{}
		}
	}
</script>